import colors from "constants/colors";
import React, { useEffect, useRef } from "react";
import Switch from "react-switch";
import CTAButton from "shared/cta-button";
import ScoutTitle from "shared/scout-title";
import UnderlineButton from "shared/underline-button";
import { useHistory, Link } from "react-router-dom";
import { useMe } from "../../../hooks/users";
import { useState } from "react";
import UniversityDropdown from "dropdowns/university-dropdown";
import ScholarshipsDropdown from "dropdowns/scholarships-dropdown";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
// import { User } from "models/User";
import { useUserForId } from "hooks/users";
import { useSharedScholarshipsForUser } from "hooks/scholarships";
import ShareScholarshipsModal from "modals/share-scholarship-modal";
import {
  AiFillCloseCircle,
  AiOutlineClose,
  AiOutlineCloseCircle,
} from "react-icons/ai";

function usePrevious(value) {
  const ref = useRef(null);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function ScholarshipHeader({
  mode,
  setMode,
  year,
  scholarshipBoard,
  setScholarshipBoard,
}: {
  mode: "view" | "edit" | "breakdown_view" | "breakdown_edit" | "add";
  setMode: any;
  year: string;
  scholarshipBoard: any;
  setScholarshipBoard: Function;
}) {
  const history = useHistory();
  const me = useMe();
  const firestore = useFirestore();
  const [editCollege, setEditCollege] = useState(false);
  const [selectingScholarshipBoard, setSelectingScholarshipBoard] =
    useState(false);
  const [showModal, setShowModal] = useState(false);
  const lastMode = usePrevious(mode);
  const lastBoard = usePrevious(scholarshipBoard);

  const sharedScholarships = useSharedScholarshipsForUser(me);
  const _user = useUserForId(scholarshipBoard);
  const user = _user || me;

  useEffect(() => {
    if (lastMode == "edit" && mode == "view") {
      setEditCollege(false);
    }
  }, [mode]);

  useEffect(() => {
    if (lastBoard && scholarshipBoard !== lastBoard) {
      setMode("view");
    }
  }, [scholarshipBoard]);

  const isMyBoard = scholarshipBoard === me.id;

  if (mode === "add") {
    return <div></div>;
  }

  const selectBoard = async (bd) => {
    if (bd && bd.senderId) {
      setSelectingScholarshipBoard(false);
      setScholarshipBoard(bd.senderId);
    }
  };

  if (mode === "breakdown_view" || mode === "breakdown_edit") {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: 12,
          backgroundColor: colors.lightBlue,
          zIndex: 101,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",

            alignItems: "center",
            paddingTop: 8,
            paddingBottom: 8,
          }}
        >
          <ScoutTitle
            text={`BREAKDOWN (${year}-${parseInt(year) + 1})`}
            light={true}
          />
          <div style={{ flex: 1 }} />

          {isMyBoard && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginRight: 20,
              }}
            >
              <p
                style={{
                  opacity: mode === "breakdown_view" ? 1 : 0.4,
                  color: colors.white,
                  marginRight: 8,
                  marginLeft: 8,
                  fontWeight: "bold",
                }}
              >
                VIEW MODE
              </p>
              <Switch
                checked={mode === "breakdown_edit"}
                onChange={() =>
                  setMode(
                    mode === "breakdown_edit"
                      ? "breakdown_view"
                      : "breakdown_edit"
                  )
                }
                uncheckedIcon={false}
                checkedIcon={false}
                onColor={colors.accent}
              />
              <p
                style={{
                  opacity: mode === "breakdown_edit" ? 1 : 0.4,
                  color: colors.white,
                  marginRight: 8,
                  marginLeft: 8,
                  fontWeight: "bold",
                }}
              >
                EDIT MODE
              </p>
            </div>
          )}
          <div style={{ marginRight: 12 }}>
            <UnderlineButton
              text="done"
              color={colors.white}
              onClick={() => setMode("view")}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: 12,
        backgroundColor: "transparent",
        zIndex: 101,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",

          alignItems: "center",
          paddingTop: 8,
          paddingBottom: 8,
          height: "60px",
        }}
      >
        <div style={{ flex: 1 }} />

        {isMyBoard && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginRight: 20,
            }}
          >
            <p
              style={{
                opacity: mode === "view" ? 1 : 0.4,
                color: colors.white,
                marginRight: 8,
                marginLeft: 8,
                fontWeight: "bold",
              }}
            >
              VIEW MODE
            </p>
            <Switch
              checked={mode === "edit"}
              onChange={() => setMode(mode === "edit" ? "view" : "edit")}
              uncheckedIcon={false}
              checkedIcon={false}
              onColor={colors.accent}
            />
            <p
              style={{
                opacity: mode === "edit" ? 1 : 0.4,
                color: colors.white,
                marginRight: 8,
                marginLeft: 8,
                fontWeight: "bold",
              }}
            >
              EDIT MODE
            </p>
          </div>
        )}
        {isMyBoard && (
          <div style={{ marginRight: 12 }}>
            <UnderlineButton
              text="view pdf"
              color={colors.white}
              onClick={() => history.push(`/pdf-scholarships/${year}`)}
            />
          </div>
        )}

        {isMyBoard && (
          <div style={{ marginRight: 12 }}>
            <UnderlineButton
              text="add players to roster"
              color={colors.white}
              onClick={() => setMode("add")}
            />
          </div>
        )}

        {isMyBoard && (
          <div style={{ marginRight: 12 }}>
            <UnderlineButton
              text="share"
              color={colors.white}
              onClick={() => setShowModal(true)}
            />
          </div>
        )}

        {selectingScholarshipBoard ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginRight: 20,
            }}
          >
            <ScholarshipsDropdown
              boards={sharedScholarships}
              selectBoard={(bd) => {
                selectBoard(bd[0]);
              }}
            />
          </div>
        ) : (
          <div
            style={{
              padding: 4,
              zIndex: 100,
              cursor: "pointer",
              marginRight: 20,
            }}
            onClick={() => setSelectingScholarshipBoard(true)}
          >
            <p
              style={{
                color: colors.white,
                textAlign: "center",
                whiteSpace: "nowrap",
                fontWeight: 700,
              }}
            >
              {user?.firstName || ""} {user?.lastName || ""}'s Scholarships
            </p>
          </div>
        )}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginTop: 12,
          marginRight: 20,
          marginBottom: 20,
        }}
      >
        <CTAButton
          text="VIEW BREAKDOWN"
          onClick={() => setMode("breakdown_view")}
          styles={{
            paddingLeft: 8,
            paddingRight: 8,
            backgroundColor: colors.accent,
          }}
        />
      </div>

      {editCollege ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",

            marginRight: 30,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",

              maxWidth: 350,
            }}
          >
            <p
              style={{
                opacity: 1,
                color: colors.white,
                marginRight: 8,
                marginLeft: 8,
                fontWeight: "bold",
              }}
            >
              {`COLLEGE:`}
            </p>
            <UniversityDropdown
              selectTeam={(val) => {
                firestore
                  .collection("users")
                  .doc(user.id as unknown as string)
                  .update({ college: val });
                setEditCollege(false);
              }}
            />
            <div onClick={() => setEditCollege(false)}>
              <AiOutlineCloseCircle
                style={{ color: colors.accent, fontSize: 24, marginLeft: 2 }}
              />
            </div>
          </div>
        </div>
      ) : user?.college ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            borderRadius: 5,
            marginRight: 10,
            // cursor: "pointer",
          }}
          // onClick={() => setEditCollege(true)}
        >
          <p
            style={{
              opacity: 1,
              color: colors.white,
              marginRight: 4,
              marginLeft: 8,
              fontWeight: "bold",
            }}
          >
            {`COLLEGE:`}
          </p>

          <p
            style={{
              color: colors.white,
              fontWeight: "bold",
              marginRight: 8,
              textDecorationLine: "underline",
              textDecorationStyle: "solid",
              textDecorationColor: colors.white,
            }}
          >
            {`${user?.college}`.toUpperCase()}
          </p>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            borderRadius: 5,
            marginRight: 10,
            cursor: "pointer",
          }}
          onClick={() => setEditCollege(true)}
        >
          <p
            style={{
              opacity: 1,
              color: colors.white,
              marginRight: 4,
              marginLeft: 8,
              fontWeight: "bold",
              textDecorationLine: "underline",
              textDecorationStyle: "solid",
              textDecorationColor: colors.white,
            }}
          >
            {`ADD COLLEGE`}
          </p>
        </div>
      )}
      <ShareScholarshipsModal
        show={showModal}
        board={scholarshipBoard}
        onHide={() => setShowModal(false)}
      />
    </div>
  );
}

export default ScholarshipHeader;
