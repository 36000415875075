import { TEAMS } from "constants/data";
import colors from "constants/colors";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useFirestore, withFirestore } from "react-redux-firebase";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { compose } from "recompose";
import { FBAnalytics } from "store/store";
import "../../App.css";
import AffiliatedTeamDropdown from "../../dropdowns/affiliated-team-dropdown";
import { checkUserEmailExists } from "services/login-service";
import "./signup.css";

import { Column } from "components/basic";

import { GoogleLoginButton } from "./shared";
import { useFlags } from "hooks/flags";
import { validateEmailFormat } from "services/utils";
// import { User } from "models/User";

function SignUp(props: any) {
  let history = useHistory();
  const flags = useFlags();
  const historyState = history.location?.state || {};
  const firestore = useFirestore();
  const { register, handleSubmit, errors } = useForm();
  const [phase, setPhase] = useState<"INIT"|"DETAILS">(historyState.phase || "INIT");
  const [email, setEmail] = React.useState(historyState.email || "");
  const [password, setPassword] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [passwordConfirm, setPasswordConfirm] = React.useState("");
  const [first, setFirst] = React.useState(historyState.firstName || "");
  const [last, setLast] = React.useState(historyState.lastName || "");
  const [college, setCollege] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [affTeam, setAffteam] = React.useState("");
  const [hasChecked, setHasChecked] = React.useState(false);
  const [googleAccessToken, setGoogleAccessToken] = useState(historyState.googleAccessToken || null);
  const [userId, setUserId] = useState(historyState.userId || null);
  const [inputErrors, setInputErrors] = useState({
    email: null,
  });

  const hasFormErrors = Object.values(errors || {}).reduce((accum, curr) => accum || curr, false);
  const hasInputErrors = Object.values(inputErrors || {}).reduce((accum, curr) => accum || curr, false);
  const hasErrors = hasFormErrors || hasInputErrors || !hasChecked;

  const submit = async (data, e) => {
    switch (phase) {
      case "INIT":
        if (hasChecked ){
          setPhase("DETAILS");
        }
        break
      case "DETAILS":
        submitNewUser();
        break;
      default:
        console.log("INVALID PHASE!");
        break;
    }
  };

  const handleNewSsoUser = (userData) => {
    setEmail(userData.email);
    setFirst(userData.firstName);
    setLast(userData.lastName);

    setUserId(userData.userId)

    if (userData.googleAccessToken) {
      setGoogleAccessToken(userData.googleAccessToken);
    }

    setPhase("DETAILS");

    console.log("HANDLED")
  }

  console.log(phase, first);

  const submitNewUser = async () => {
    localStorage.setItem("scoutEmail", email);

    const newUserData = {
      college: college,
      title: title,
      email: email,
      firstName: first,
      lastName: last,
      phoneNumber: phone,
      timeCreated: new Date(),
      friends: [],
      badgeCount: 0,
      notifyFriendRequests: true,
      notifyPlayerMatching: false,
      notifyMessages: true,
      theme: "Dark",
      emailNotifications: false,
      affiliatedTeam: affTeam,
      sharedBoards: [],
      verified: false,
    }

    let res = null;
    
    try {
      if (userId) {
        res = await firestore
          .collection("users")
          .doc(userId)
          .set(newUserData, { merge: true })
      } else {
        res = await props.firebase.createUser(
          { email, password },
          newUserData,
        );
      }

      if (res) {
        await firestore
          .collection("boards")
          .doc(res.uid)
          .set({
            name: `${first} ${last}'s Big Board`,
            ownerName: `${first} ${last}`,
            ownerId: res.uid,
            isSecondary: false,
            recipientIds: [res.uid],
          });
      }
      await onSignIn();
    } catch (err) {
      setPhase("INIT");
      setEmail("");
      setPassword("");
      setPasswordConfirm("");
      toast.dark((err as any).message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  }

  const verifyEmail = async () => {
    await props.firebase
      .auth()
      .currentUser.sendEmailVerification()
      .catch((err) => {
        console.log("SEND EMAIL ERR:", err);
      });
  }

  const onSignIn = async () => {
    //@ts-ignore
    FBAnalytics.logEvent("sign_up");

    await verifyEmail();
    history.replace("home");
  };

  const defaultOption = TEAMS[0];

  useEffect(() => {
    const validateEmail = async () => {
      const validEmailEdu = !flags.force_edu_login || email?.endsWith(".edu");
      const emailExists = await checkUserEmailExists(email);
      const validEmailFormat = validateEmailFormat(email);
      
      setInputErrors({
        ...inputErrors,
        email: !validEmailFormat ? "Please enter a valid email!"
             : !validEmailEdu ? "Please enter a valid .edu email!"
             : emailExists ? "E-mail already in use!" : null
      });
    }
    
    if (email) {
      validateEmail();
    }
  }, [email])

  const renderEmailPass = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "30px" }}>

        {/* !blw: not sure why, but refreshes (loses state) when logging in a BRAND NEW user */}
        {/* <GoogleLoginButton onCreateNewUser={handleNewSsoUser} /> */}
        <GoogleLoginButton
          onCreateNewUser={(userData) => {
            history.replace({
              pathname: "/signup",
              state: {
                phase: "DETAILS",
                ...userData
              },
            })
            handleNewSsoUser(userData);
          }}
        />
        
        <div style={{ display: "flex", width: "100%", alignItems: "center", gap: "16px", padding: "0 16px" }}>
          <div style={{ height: "2px", background: colors.lightestBlue, flex: 1 }} />
          <div style={{ color: colors.lightBlue }}>or</div>
          <div style={{ height: "2px", background: colors.lightestBlue, flex: 1 }} />
        </div>
        
        <form onSubmit={handleSubmit(submit)}>
          <div className={"textinput-container"}>
            <Column>
              <input
                placeholder={flags.force_edu_login ? "Email (.edu)" : "Email"}
                className="input"
                onChange={(evt) => setEmail(evt.target.value)}
                ref={register({ required: true, minLength: 5 })}
                name="email"
              />
              {errors.email ? (
                <span style={{ color: "#B22222", marginBottom: "-24px" }}>
                  Please enter a valid email
                </span>
              ) : inputErrors.email && (
                <span style={{ color: "#B22222", marginBottom: "-24px" }}>
                  {inputErrors.email}
                </span>
              )}
            </Column>

            <Column>
              <input
                type="password"
                placeholder="Password"
                name="password"
                className={"input"}
                onChange={(evt) => setPassword(evt.target.value)}
                ref={register({ required: true, minLength: 5 })}
              />
              {errors.password && (
                <span style={{ color: "#B22222", marginBottom: "-24px" }}>
                  Password must be at least 5 characters.
                </span>
              )}
            </Column>

            <Column>
              <input
                type="password"
                name="passwordConfirm"
                placeholder="Confirm Password"
                className={"input"}
                onChange={(evt) => setPasswordConfirm(evt.target.value)}
                ref={register({
                  required: true,
                  validate: async (value) => value === password,
                })}
              />
              {errors.passwordConfirm && (
                <span style={{ color: "#B22222", marginBottom: "-24px" }}>
                  Passwords must match.
                </span>
              )}
            </Column>
          </div>
          {renderAccept()}

          <input
            disabled={hasErrors}
            className={"button-text signin-button"}
            style={{
              color: "#fff",
              border: "none",
              opacity: hasErrors ? 0.3 : undefined,
            }}
            type="submit"
            value="SIGN UP"
          />
        </form>
      </div>
    );
  };

  const renderDetail = () => {
    return(
      <form onSubmit={handleSubmit(submit)}>
        <div className={"textinput-container"}>
          <div /> {/* gap */}
          <Column>
            <input
              type="text"
              placeholder="First Name"
              value={first}
              className={"input"}
              name="first"
              onChange={(evt) => setFirst(evt.target.value)}
              ref={register({ required: true, minLength: 3 })}
            />
            {errors.first && (
              <span style={{ color: "#B22222", marginBottom: "-24px" }}>
                Please enter your first name.
              </span>
            )}
          </Column>

          <Column>
            <input
              type="text"
              placeholder="Last Name"
              value={last}
              className={"input"}
              name={"last"}
              onChange={(evt) => setLast(evt.target.value)}
              ref={register({ required: true, minLength: 3 })}
            />
            {errors.last && (
              <span style={{ color: "#B22222", marginBottom: "-24px" }}>
                Please enter your last name.
              </span>
            )}
          </Column>

          <Column>
            <input
              type="text"
              placeholder="Phone Number"
              className={"input"}
              name="phone"
              onChange={(evt) => setPhone(evt.target.value)}
              ref={register({
                required: true,
                minLength: 10,
                pattern: /^[0-9]*$/,
              })}
            />
            {errors.phone && (
              <span style={{ color: "#B22222", marginBottom: "-24px" }}>
                Please enter a valid phone number.
              </span>
            )}
          </Column>

          <input
            type="text"
            placeholder="College/University"
            className={"input"}
            name="college"
            onChange={(evt) => setCollege(evt.target.value)}
          />

          <input
            type="text"
            placeholder="Title"
            className={"input"}
            name="title"
            onChange={(evt) => setTitle(evt.target.value)}
          />
          
          <Column>
            <p
              className={"affiliated-text"}
            >
              Affiliated Team
            </p>
            <AffiliatedTeamDropdown selectTeam={(val) => setAffteam(val)} />
          </Column>
        </div>

        <input
          className={"button-text signin-button"}
          style={{ color: "#fff", border: "none" }}
          type="submit"
          value="FINISH"
        />
      </form>
    );
  }


  const renderAccept = () => {
    return (
      <div>
        <div className={"accept-container"}>
          <input
            type="checkbox"
            style={{ marginRight: "0.5rem" }}
            onClick={() => setHasChecked(!hasChecked)}
            ref={register({
              required: true,
              validate: async (value) => value === true,
            })}
          />

          <p className={"accept-left-text"}>I accept Scout</p>
          <Link
            className="accept-blue-text"
            style={{ textDecoration: "none" }}
            to="/terms-of-service"
          >
            Terms of Service
          </Link>
        </div>
        <div className={"accept-container"} style={{ marginTop: 3 }}>
          <p className={"accept-left-text"}>and</p>
          <Link
            className="accept-blue-text"
            style={{ textDecoration: "none" }}
            to="/privacy-policy"
          >
            Privacy Policy
          </Link>
        </div>
        {errors.passwordConfirm && (
          <span style={{ color: "#B22222" }}>Please accept terms.</span>
        )}
      </div>
    );
  };

  return (
    <div className="outer-container">
      <div
        style={{
          display: "flex",
          flex: 6,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <p className={"signin-text"} style={{ marginBottom: 40 }}>
          BECOME A PART OF THE TEAM
        </p>
        <div className={"signup-container"}>
          {phase === "INIT" && renderEmailPass()}
          {phase === "DETAILS" && (
            renderDetail()
          )}
          <div className={"havent-container"}>
            <p className={"havent-left-text"}>Already have an account?</p>
            <div className={"havent-right-text-link"}>
              <Link
                className="havent-right-text"
                style={{ textDecoration: "none" }}
                to="/login"
              >
                Sign In
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div style={{ position: "absolute", top: 20, left: 20 }}>
        <Link
          className="navbar-brand"
          style={{ textDecoration: "none", fontWeight: 700 }}
          to="/"
        >
          <div
            style={{ display: "flex", flexDirection: "row", color: "white" }}
          >
            <h3 style={{ fontWeight: 800 }}>Scout</h3>
            <h3 style={{ fontWeight: 300 }}>NET</h3>
          </div>
        </Link>
      </div>
    </div>
  );
}

const enhance = compose(withFirestore);

export default enhance(SignUp);
