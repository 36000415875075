import colors from "constants/colors";
import React, { useState } from "react";
import { compose } from "recompose";
import {
  isLoaded,
  withFirestore,
  useFirestoreConnect,
} from "react-redux-firebase";
import { useSelector } from "react-redux";
import { Button, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { QueryHelper } from "services/QueryHelper";
import { useLocation } from "react-router-dom";

function SaveSearchModal(props: {
  firestore: any;
  onHide: any;
  url: string;
  count: number;
  position?: string;
  query: any;
  ncaaPortal?: any;
}) {
  const userId = useSelector((state: any) => state.firebase.auth.uid);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const save = () => {
    setLoading(true);
    if (props.ncaaPortal) {
      const docRefConfig = {
        collection: "users",
        doc: userId,
        subcollections: [{ collection: "searches" }],
      };

      let search = {
        count: props.count,
        active: props.count < 51,
        name: name,
        userId: userId,
        timeCreated: new Date(),
        query: props.query,
        ncaaPortal: props.ncaaPortal,
      };
      props.firestore.add(docRefConfig, search);
      toast.dark(`Search saved!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
      setLoading(false);
      props.onHide();
    } else {
      QueryHelper.getAllForURL(props.url).then((data) => {
        let playerIds = (data.data || []).map((item) => item.external_id);
        const docRefConfig = {
          collection: "users",
          doc: userId,
          subcollections: [{ collection: "searches" }],
        };
        let search = {
          url: props.url,
          count: props.count,
          active: props.count < 51,
          name: name,
          playerIds: playerIds,
          userId: userId,
          timeCreated: new Date(),
          position: props.position,
          query: props.query,
          webUrl: location.search,
        };
        props.firestore.add(docRefConfig, search);
        toast.dark(`Search saved!`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
        setLoading(false);
        props.onHide();
      });
    }
  };

  return (
    <Modal
      {...props}
      //@ts-ignore
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          borderBottomWidth: 0,
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          Save Search
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          display: "flex",
          flexDirection: "column",
        }}
        className="searching"
      >
        {loading ? (
          <Spinner animation="border" />
        ) : (
          <input
            type="text"
            style={{
              marginTop: 20,
              marginBottom: 10,
              display: "flex",
              flex: 1,
              border: "0px solid",
              color: "#fff",
              backgroundColor: "transparent",
              borderBottom: "2px solid #fff",
            }}
            placeholder="Enter Search Name"
            onChange={(evt) => setName(evt.target.value)}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={props.onHide}
          style={{
            borderColor: colors.accent,
            backgroundColor: colors.white,
            color: colors.accent,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            save();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const enhance = compose(withFirestore);

export default enhance(SaveSearchModal);
