
import firebase from "firebase/app";
import "firebase/firestore";

export const getAhowAllQuery = async (pageSize: number) => {
  try {
    const ncaa_portals = await firebase
      .firestore()
      .collection("ncaa_portals")
      .limit(pageSize)
      .get();
    const ncaa_portals_data = ncaa_portals.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return ncaa_portals_data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getTotalItemCount = async () => {
  try {
    const ncaa_portals = await firebase
      .firestore()
      .collection("ncaa_portals")
      .get();
    const totalCount = ncaa_portals.size;
    return totalCount;
  } catch (error) {
    console.error("Error getting total count:", error);
    return 0;
  }
};

export const fetchPaginatedData = async (pageSize, lastDoc) => {
  try {
    let query = firebase.firestore().collection("ncaa_portals").limit(pageSize);
    if (lastDoc) {
      query = query.startAfter(lastDoc);
    }
    const snapshot = await query.get();
    const fetchedData = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return {
      data: fetchedData,
      lastDoc: snapshot.docs[snapshot.docs.length - 1],
    };
  } catch (error) {
    console.error("Error fetching paginated data:", error);
    return { data: [], lastDoc: null };
  }
};

export const fetchFilterData = async (
  pageSize = 20,
  filters,
  lastDoc = null
) => {
  try {
    let query: firebase.firestore.Query<firebase.firestore.DocumentData> = firebase.firestore().collection("ncaa_portals");
    if (filters.conference) {
      query = query.where("conference", "==", filters.conference);
    }
    if (filters.division && filters.division.length > 0) {
      query = query.where("division", "in", filters.division);
    }
    if (typeof filters.graduate === "boolean") {
      query = query.where("is_graduate", "==", filters.graduate ? "Y" : "N");
    }
    if (filters.institution) {
      query = query.where("institution", "==", filters.institution);
    }
    if (typeof filters.mtr === "boolean") {
      query = query.where(
        "multiple_transfer_portal_records",
        "==",
        filters.mtr ? "Y" : "N"
      );
    }
    if (filters.transferStatus && filters.transferStatus.length > 0) {
      query = query.where("transfer_status", "in", filters.transferStatus);
    }
    if (filters.year) {
      query = query.where("year", "==", filters.year);
    }

    const snapshot = await query.limit(pageSize).get();
    const fetchedData = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return {
      data: fetchedData,
      lastDoc: snapshot.docs[snapshot.docs.length - 1],
    };
  } catch (error) {
    console.error("Error fetching paginated data:", error);
    return { data: [], lastDoc: null };
  }
};
