import colors from "constants/colors";
import React, { useState, useEffect } from "react";
import Select from "react-select";

interface DropdownProps {
  options: { id: string; institution: string }[];
  onSelect: (item: { id: string; institution: string } | null) => void;
  placeHolder?: string;
  value?: string;
}

const InstitutionDropdown: React.FC<DropdownProps> = ({
  options,
  onSelect,
  placeHolder = "Select an option",
  value = "",
}) => {
  const [searchTerm, setSearchTerm] = useState(value);
  const [selectedOption, setSelectedOption] = useState<{
    value: string;
    label: string;
  } | null>(
    value
      ? {
          value,
          label: options.find((opt) => opt.id === value)?.institution || "",
        }
      : null
  );

  // Format options to match react-select structure
  const formattedOptions = options.map((option) => ({
    value: option.id,
    label: option.institution,
  }));

  const handleSelect = (option: { value: string; label: string } | null) => {
    if (option) {
      const selectedItem = options.find((opt) => opt.id === option.value);
      if (selectedItem) {
        setSelectedOption(option);
        onSelect(selectedItem);
        setSearchTerm(option.label);
      }
    } else {
      // Clear selection
      setSelectedOption(null);
      onSelect(null);
      setSearchTerm("");
    }
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      minWidth: 250,
      maxWidth: 320,
    }),
    multiValue: (styles: any) => ({
      ...styles,
      backgroundColor: colors.lightBlue,
    }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      color: colors.white,
    }),
    multiValueRemove: (styles: any) => ({
      ...styles,
      color: colors.white,
      ":hover": {
        backgroundColor: colors.accent,
      },
    }),
  };

  const allowAll = true;

  return (
    <div style={styles.container}>
      <Select
        options={formattedOptions.filter((item) =>
          allowAll ? true : item.label !== "Institutions"
        )}
        value={selectedOption}
        onChange={handleSelect}
        placeholder={placeHolder}
        styles={customStyles}
      />
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
    marginBottom: 15,
  },
};

export default InstitutionDropdown;
