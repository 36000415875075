import colors from "constants/colors";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useFirestore } from "react-redux-firebase";
import qs from "qs";
import { PlayerQuery } from "models/Query";
import { useEffect } from "react";
import AdvancedSearch from "pages/search-page/advanced-search";
import { QueryHelper } from "services/QueryHelper";
import { RANGE_TITLES, TITLE_FOR_KEY } from "constants/search";

function EditSavedSearchModal(props: {
  onHide: any;
  title: string;
  show?: any;
  savedSearch: any;
  ncaaPortal?: boolean;
}) {
  const [name, setName] = useState(null);
  const firestore = useFirestore();
  const userId = useSelector((state: any) => state.firebase.auth.uid);
  const [query, setQuery] = useState(null);
  if (props.savedSearch && props.savedSearch.name && !name) {
    setName(props.savedSearch.name);
  }
  console.log(props.savedSearch, "props.savedSearch");

  if (props.savedSearch && props.savedSearch.query && !query) {
    setQuery(props.savedSearch.query);
  }

  const queryAsString = () => {
    var str = [];
    for (var p in query)
      if (query.hasOwnProperty(p)) {
        if (RANGE_TITLES.includes(TITLE_FOR_KEY[p])) {
          str.push(p + "=" + query[p].min + "," + query[p].max);
        } else if (p === "states" || p === "positions") {
          if (query[p].length > 0) {
            str.push(p + "=" + query[p].join(","));
          }
        } else {
          str.push(p + "=" + query[p]);
        }
      }
    return str.join("&");
  };

  const updateSavedSearch = async () => {
    if (props.savedSearch.webUrl) {
      let url = QueryHelper.urlForQuery(query);
      QueryHelper.getAllForURL(url).then(async (data) => {
        let playerIds = (data.data || []).map((item) => item.external_id);

        let count = data.count;

        await firestore
          .collection("users")
          .doc(userId)
          .collection("searches")
          .doc(props.savedSearch.id)
          .update({
            name: name,
            url: url,
            count: count,
            active: count < 51,

            playerIds: playerIds,

            timeUpdated: new Date(),
            position:
              query.positions && query.positions.length > 0
                ? query.positions[0]
                : props.savedSearch.position,
            query: query,
            webUrl: `/search-results?${queryAsString()}`,
          });
        props.onHide();
        setName(null);
        setQuery(null);
      });
    } else {
      await firestore
        .collection("users")
        .doc(userId)
        .collection("searches")
        .doc(props.savedSearch.id)
        .update({ name: name });
      props.onHide();
      setName(null);
    }
  };

  const updateNCAAPortal = async (updatedItem) => {
    await firestore
      .collection("users")
      .doc(userId)
      .collection("searches")
      .doc(props.savedSearch.id)
      .update({ name: name });
    props.onHide();
    setName(null);
    props.onHide();
    setName(null);
    setQuery(null);
  };

  return (
    <Modal
      {...props}
      //@ts-ignore
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      //@ts-ignore
      onHide={() => {
        props.onHide();
        setName(null);
        setQuery(null);
      }}
    >
      <Modal.Header
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          borderBottomWidth: 0,
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body
        style={{ color: colors.white, backgroundColor: colors.accent }}
      >
        <div className="searching" style={{ marginTop: -12 }}>
          <p style={{ fontWeight: "bold" }}>SEARCH NAME:</p>
          <input
            type="text"
            style={{
              marginBottom: 10,
              display: "flex",
              flex: 1,
              border: "0px solid",
              color: "#fff",
              width: 400,
              backgroundColor: "transparent",
              borderBottom: "2px solid #fff",
            }}
            value={name}
            placeholder="Saved Search Name"
            onChange={(evt) => setName(evt.target.value)}
          />
        </div>
        {!props.ncaaPortal && query && (
          <AdvancedSearch
            query={query}
            isFull={false}
            setQuery={setQuery}
            sendUrl={(url) => {}}
            setShowAdvanced={() => {}}
            save={(query) => {}}
            clear={() => {}}
            setLoading={() => {}}
            minimal={true}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            props.onHide();
            setName(null);
            setQuery(null);
          }}
          style={{
            borderColor: colors.accent,
            backgroundColor: colors.white,
            color: colors.accent,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={async () => {
            await firestore
              .collection("users")
              .doc(userId)
              .collection("searches")
              .doc(props.savedSearch.id)
              .delete();
            props.onHide();
            setName(null);
            setQuery(null);
          }}
          style={{
            borderColor: colors.accent,
            backgroundColor: colors.white,
            color: colors.accent,
          }}
        >
          Delete Search
        </Button>
        <Button
          onClick={props.ncaaPortal ? updateNCAAPortal : updateSavedSearch}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditSavedSearchModal;
