import colors from "constants/colors";
// import NotificationsList from "header/notifications-list";
import HelpModal from "modals/help-modal";
import React, { useState } from "react";
import ScoutTitle from "shared/scout-title";

function NotificationsPage(props: any) {
  const [showHelp, setShowHelp] = useState(false);

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        minHeight: "80vh",
        marginLeft: "1rem",
        marginRight: "1rem",
      }}
    >
      <div
        style={{
          backgroundColor: colors.white,
          marginBottom: 20,
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingRight: 8,
          }}
        >
          <ScoutTitle
            text={"Notifications"}
            withHelp={true}
            onClick={() => setShowHelp(true)}
          />
        </div>
        {/* <NotificationsList includeClear={true} /> */}
      </div>

      <HelpModal
        show={showHelp}
        kind={"NOTIFICATIONS"}
        onHide={() => setShowHelp(false)}
      />
    </div>
  );
}

export default NotificationsPage;
