import colors from "constants/colors";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { AiFillCaretUp } from "react-icons/ai";
import { AiFillCaretDown } from "react-icons/ai";

function ResultHeader({
  currentSortType,
  currentSortDirection,
  setCurrentSortType,
  setCurrentSortDirection,
  handleSort,
  onlyCollege,
  disabledMode,
}: {
  currentSortType: any;
  currentSortDirection: any;
  setCurrentSortType: any;
  setCurrentSortDirection: any;
  handleSort: any;
  onlyCollege: any;
  disabledMode: "pbr_disabled" | "pg_disabled" | "both" | "none";
}) {
  const toggleSortDirection = () => {
    if (currentSortDirection === "asc") {
      handleSort(currentSortType, "desc");
      setCurrentSortDirection("desc");
    } else if (currentSortDirection === "desc") {
      setCurrentSortDirection(null);
      setCurrentSortType(null);
      handleSort(null, null);
    } else {
      handleSort(currentSortType, "asc");
      setCurrentSortDirection("asc");
    }
  };

  const renderArrow = (name) => {
    if (name === currentSortType) {
      if (currentSortDirection === "asc") {
        return <AiFillCaretDown style={{ marginLeft: 6, marginTop: 4 }} />;
      } else {
        return <AiFillCaretUp style={{ marginLeft: 6, marginTop: 4 }} />;
      }
    } else {
      return <div style={{ width: 20 }} />;
    }
  };

  const selectSort = (name) => {
    if (name === currentSortType) {
      toggleSortDirection();
    } else {
      setCurrentSortType(name);
      setCurrentSortDirection("asc");
      handleSort(name, "asc");
    }
  };

  const headers = [
    { id: 1, name: "FirstName", width: 150, key: "first_name" },
    { id: 2, name: "LastName", width: 150, key: "last_name" },
    { id: 3, name: "Institution", width: 250, key: "institution" },
    { id: 4, name: "Graduate", width: 120, key: "is_graduate" },
    { id: 5, name: "MTR", width: 100, key: "multiple_transfer_portal_records" },
    { id: 6, name: "Transfer Status", width: 150, key: "transfer_status" },
    { id: 7, name: "Year", width: 100, key: "year" },
    { id: 8, name: "Conference", width: 150, key: "conference" },
    { id: 9, name: "Division", width: 120, key: "division" },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: colors.lightBlue,
        color: colors.white,
        marginBottom: 10,
        // overflow: "hidden",
        minWidth: onlyCollege ? 700 : 1350,
      }}
    >
      <div
        style={{
          width: 50,
          minWidth: 50,
        }}
      ></div>
      <div
        style={{
          width: 30,
          minWidth: 30,
        }}
      ></div>
      <div
        style={{
          flex: 1,
          minWidth: 200,
          maxWidth: 250,
          display: "flex",
          flexDirection: "row",
          cursor: "pointer",
        }}
        onClick={() => selectSort("Player")}
      >
        <p style={{ fontWeight: "bold" }}>Player</p>
        {renderArrow("Player")}
      </div>
      <div
        style={{
          width: 95,
          minWidth: 95,
          display: "flex",
          flexDirection: "row",
          cursor: "pointer",
        }}
        onClick={() => selectSort("Position")}
      >
        <p style={{ fontWeight: "bold" }}>Position</p>
        {renderArrow("Position")}
      </div>

      <div
        style={{
          width: 75,
          minWidth: 75,
          display: "flex",
          flexDirection: "row",
          cursor: "pointer",
        }}
        onClick={() => selectSort("Height")}
      >
        <p style={{ fontWeight: "bold" }}>Height</p>
        {renderArrow("Height")}
      </div>
      <div
        style={{
          width: 75,
          minWidth: 75,
          display: "flex",
          flexDirection: "row",
          cursor: "pointer",
        }}
        onClick={() => selectSort("Weight")}
      >
        <p style={{ fontWeight: "bold" }}>Weight</p>
        {renderArrow("Weight")}
      </div>

      <div
        style={{
          width: onlyCollege ? 125 : 110,
          minWidth: onlyCollege ? 125 : 110,
          display: "flex",
          flexDirection: "row",
          cursor: "pointer",
        }}
        onClick={() => selectSort("Class")}
      >
        <p
          style={{
            fontWeight: "bold",
            textAlign: onlyCollege ? "left" : "right",
            width: 70,
          }}
        >
          Class
        </p>
        {renderArrow("Class")}
      </div>

      {onlyCollege ? (
        <div />
      ) : (
        <div
          style={{
            marginLeft: -10,
            width: 70,
            minWidth: 70,
            display: "flex",
            flexDirection: "row",
            cursor: "pointer",
          }}
          onClick={() => selectSort("Location")}
        >
          <p style={{ fontWeight: "bold" }}>State</p>
          {renderArrow("Location")}
        </div>
      )}

      {onlyCollege ? (
        <div />
      ) : (
        <div
          style={{
            width: 130,
            minWidth: 130,
            display: "flex",
            flexDirection: "row",
            cursor: "pointer",
          }}
          onClick={() => selectSort("National Rank")}
        >
          <p style={{ fontWeight: "bold" }}>National Rank</p>
          {renderArrow("National Rank")}
        </div>
      )}

      {onlyCollege ? (
        <div />
      ) : (
        <div
          style={{
            width: 110,
            minWidth: 110,
            display: "flex",
            flexDirection: "row",
            cursor: "pointer",
          }}
          onClick={() => selectSort("State Rank")}
        >
          <p style={{ fontWeight: "bold" }}>State Rank</p>
          {renderArrow("State Rank")}
        </div>
      )}

      {onlyCollege ? (
        <div />
      ) : (
        <div
          style={{
            flex: 1,
            minWidth: 160,

            display: "flex",
            flexDirection: "row",
            cursor: "pointer",
          }}
          onClick={() => selectSort("Team")}
        >
          <p style={{ fontWeight: "bold", paddingLeft: 8 }}>Team</p>
          {renderArrow("Team")}
        </div>
      )}

      <div
        style={{
          flex: 1,
          minWidth: 120,
          display: "flex",
          flexDirection: "row",
          cursor: "pointer",
        }}
        onClick={() => selectSort("Commitment")}
      >
        <p style={{ fontWeight: "bold" }}>Commitment</p>
        {renderArrow("Commitment")}
      </div>
    </div>
  );
}

export default ResultHeader;
