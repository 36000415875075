import { useMe } from "../../../hooks/users";
import colors from "constants/colors";
import { Player } from "models/Player";
import React, { FC, useCallback, useState } from "react";
import { useDrop } from "react-dnd";
import { useFirestore } from "react-redux-firebase";
import { PlayerService } from "services/PlayerService";
import EditablePlayerItem, { ItemTypes } from "./editable-player-item";
import ReadonlyPlayerItem from "./readonly-player-item";

interface Props {
  playerKind: string;
  players: Player[];
  year: string;
  mode: "view" | "edit" | "breakdown_view" | "breakdown_edit" | "add";
}

function selectBackgroundColor(isActive: boolean, canDrop: boolean) {
  if (isActive) {
    return colors.accent;
  } else if (canDrop) {
    return colors.lightBlue;
  } else {
    return "transparent";
  }
}

function selectFontColor(isActive: boolean, canDrop: boolean) {
  if (isActive) {
    return colors.white;
  } else if (canDrop) {
    return colors.white;
  } else {
    return colors.accent;
  }
}

export const PlayersList: FC<Props> = ({ playerKind, players, year, mode }) => {
  const firestore = useFirestore();
  const me = useMe();
  const [visible, setVisible] = useState(true);

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: ItemTypes.CARD,
      drop: () => ({
        name: playerKind,
        allowedDropEffect: "move",
      }),
      collect: (monitor: any) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    ["move"]
  );

  const renderRegularList = () => {
    if (mode === "edit") {
      return (
        <div
          style={{
            overflow: "hidden",
            clear: "both",
          }}
        >
          {players.map((item, index) => (
            <EditablePlayerItem
              hidden={!visible}
              player={item}
              year={year}
              index={index}
              moveCard={moveCard}
            />
          ))}
        </div>
      );
    }

    return players.map((item, index) => (
      <ReadonlyPlayerItem player={item} year={year} key={index} />
    ));
  };

  const isActive = canDrop && isOver;
  const backgroundColor = selectBackgroundColor(isActive, canDrop);
  const textColor = selectFontColor(isActive, canDrop);

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      setVisible(false);
      PlayerService.updateRosterOrdering(
        me.id as unknown as string,
        players,
        dragIndex,
        hoverIndex,
        firestore
      );
      setTimeout(() => {
        setVisible(true);
      }, 50);
    },
    [players]
  );

  return (
    <div
      ref={drop}
      style={{
        display: "flex",
        flexDirection: "column",
        width: mode === "edit" ? 315 : 270,
        backgroundColor: backgroundColor,
        marginTop: 20,
        padding: mode == "edit" ? 8 : 0,
        minHeight: 120,
        zIndex: 102,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <p style={{ fontWeight: "bold", color: textColor, fontSize: 20 }}>
          {playerKind}
        </p>
      </div>

      {renderRegularList()}
    </div>
  );
};

export default PlayersList;
