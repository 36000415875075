import React, { useState } from "react";
import { isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { PositionReport } from "models/PositionReport";
import { Link } from "react-router-dom";
import PDFPositionProReport from "../reports-display-page/displays/pdf-position-pro-report";
import UnderlineButton from "shared/underline-button";
import colors from "constants/colors";
import PDFGeneralReport from "../reports-display-page/displays/pdf-general-report";
import PDFPitcherReport from "../reports-display-page/displays/pdf-pitcher-report";
import PDFNotesReport from "../reports-display-page/displays/pdf-notes-report";
import LoadingView from "shared/loading-view";
import NotesReport from "pages/reports-display-page/displays/notes-report";
import ImagesList from "pages/reports-display-page/components/images-list";
import moment from "moment";
import PitcherReport from "pages/reports-display-page/displays/pitcher-report";
import PositionProReport from "pages/reports-display-page/displays/position-pro-report";
import GeneralReport from "pages/reports-display-page/displays/general-report";

function PDFPage(props: any) {
  let { reportId, userId } = useParams();

  const currentReport: any = useSelector<PositionReport>(
    (state: any) => state.firestore.data.currentReport
  );

  useFirestoreConnect([
    {
      collection: "users",
      doc: userId,
      subcollections: [{ collection: "reports", doc: reportId }],
      storeAs: "currentReport",
    },
  ]);

  if (!isLoaded(currentReport)) {
    return <LoadingView />;
  }

  const renderPositionReport = () => {
    if (currentReport.kind === "PRO") {
      return <PositionProReport posReport={currentReport} />;
    }
    return <div />;
  };

  const renderPitcherReport = () => {
    if (currentReport.kind === "PITCHER") {
      return <PitcherReport posReport={currentReport} />;
    }
    return <div />;
  };

  const renderGeneralReport = () => {
    if (currentReport.kind === "GENERAL") {
      return (
        <div style={{ marginBottom: 20 }}>
          <GeneralReport posReport={currentReport} />
        </div>
      );
    }
    return <div />;
  };

  const renderNotes = () => {
    if (currentReport.kind === "NOTES") {
      return (
        <div style={{ marginBottom: 20 }}>
          <NotesReport posReport={currentReport} />
        </div>
      );
    }
    return <div />;
  };

  return currentReport ? (
    <div
      style={{
        padding: 0,
        margin: 0,

        backgroundColor: colors.white,
        marginTop: -20,
        minHeight: "100vh",
        fontFamily: "Raleway",
      }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 50,

          display: "flex",
          flexDirection: "column",
          flex: 1,

          padding: 20,
          color: colors.accent,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingLeft: 10,
          }}
        >
          <h2 style={{ fontFamily: "Raleway", color: colors.accent }}>
            {currentReport ? currentReport.playerName : ""}
          </h2>
        </div>

        <p style={{ paddingLeft: 10 }}>
          Report
          {currentReport.date
            ? moment(new Date(currentReport.date.seconds * 1000)).format(
                " MM/DD/YYYY"
              )
            : ""}
        </p>

        {renderPositionReport()}
        {renderPitcherReport()}
        {renderGeneralReport()}
        {renderNotes()}

        <div style={{ paddingRight: 20 }}>
          {currentReport.kind !== "NOTES" ? (
            <ImagesList posReport={currentReport} large={true} />
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{
        paddingLeft: "1rem",
        margin: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <p>Sorry, we can't find that report.</p>
    </div>
  );
}

export default PDFPage;
