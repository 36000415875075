import React, { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import AdvancedSearchPortal from "./AdvancedSearchPortal";
import PortalResults from "./PortalResult";

const PAGE_SIZE = 20;

const PortalSearch = ({ isNCAA }) => {
  const [showAll, setShowAll] = useState(false);
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [showFilterIcon, setShowFilterIcon] = useState(false);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(true);
  const [showAllAndAdvanced, setShowAllAndAdvanced] = useState(false);
  const [showFilterPortal, setShowFilterPortal] = useState(false);

  const handleSortChange = (column) => {
    if (sortColumn === column && sortOrder === "asc") {
      setSortColumn("");
      setSortOrder("");
    } else if (sortColumn === column && sortOrder === "desc") {
      setSortOrder("asc");
    } else {
      setSortColumn(column);
      setSortOrder("desc");
    }
  };

  const handleShowAll = () => {
    setShowAllAndAdvanced(!showAllAndAdvanced);
    setShowAll(!showAll);
  };

  const handleShowFilterPortal = () => {
    setShowAllAndAdvanced(!showAllAndAdvanced);
    setShowFilterPortal(!showFilterPortal);
  };

  return (
    <div
      style={{
        paddingBottom: 80,
        width: showAll || showFilterPortal ? "auto" : "1000px",
      }}
    >
      <div style={{ paddingBottom: 25 }}>
        <div style={{ marginTop: 25 }}></div>
        {!showAll ? (
          <>
            {!showFilterPortal && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ color: "white", fontWeight: "bold", fontSize: 26 }}>
                  Advanced Search
                </p>
                <button
                  style={{
                    backgroundColor: "transparent",
                    color: "#fff",
                    padding: 4,
                    border: "2px solid white",
                    width: "30%",
                    borderRadius: 8,
                    marginLeft: 20,
                  }}
                  onClick={() => handleShowAll()}
                >
                  Show All
                </button>
              </div>
            )}
            <AdvancedSearchPortal
              setShowAdvancedOptions={setShowAdvancedOptions}
              setShowFilterIcon={setShowFilterIcon}
              showAll={showFilterPortal}
              handleShowAll={handleShowFilterPortal}
              isNCAA={isNCAA}
              showAdvancedOptions={showAdvancedOptions}
            />
          </>
        ) : (
          <div>
            <PortalResults showWithoutFilter={false} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PortalSearch;
