import colors from "constants/colors";
import React, { useState } from "react";
import { compose } from "recompose";
import {
  isLoaded,
  useFirestoreConnect,
  withFirestore,
} from "react-redux-firebase";
import { Button, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
// import { User } from "models/User";
import { NotificationService } from "services/NotificationService";
import { useSelector } from "react-redux";
import LoadingView from "shared/loading-view";
import { FBAnalytics } from "store/store";

function ShareScholarshipModal(props: {
  firestore: any;
  onHide: any;
  board: {
    name: String;
    senderId: String;
  };
}) {
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState<String[]>([]);
  const userId = useSelector((state: any) => state.firebase.auth.uid);
  const profile = useSelector((state: any) => state.firebase.profile);
  const users = useSelector((state: any) => state.firestore.data.users);
  useFirestoreConnect([
    { collection: "users", where: ["friends", "array-contains", userId] },
  ]);

  if (!isLoaded(profile) || !isLoaded(users)) {
    return <LoadingView />;
  }

  const sortedFriends = () => {
    let friends = users
      ? Object.keys(users).map((key) =>
          users ? { ...users[key], id: key } : null
        )
      : [];

    return friends.filter((item) => item !== null);
  };

  const send = async (user: any) => {
    setLoading(true);

    await NotificationService.sendScholarships(
      user,
      { ...profile, id: userId },
      props.board,
      props.firestore
    );

    setLoading(false);
    setSent([...sent, user.id.toString()]);

    FBAnalytics.logEvent("share", {
      from: userId,
      to: user.id,
      share_type: "scholarships",
    });

    toast.dark(`Scholarships shared with ${user.firstName} ${user.lastName}`, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  return (
    <Modal
      {...props}
      //@ts-ignore
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {props.board ? (
        <Modal.Header
          style={{
            color: colors.white,
            backgroundColor: colors.accent,
            borderBottomWidth: 0,
          }}
        >
          <Modal.Title id="contained-modal-title-vcenter">
            {`Share your scholarships overview with a friend?`}
          </Modal.Title>
        </Modal.Header>
      ) : (
        <div />
      )}
      {props.board ? (
        <Modal.Body
          style={{ color: colors.white, backgroundColor: colors.accent }}
        >
          {loading ? (
            <Spinner animation="border" />
          ) : (
            <div>
              {sortedFriends().map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: 8,
                    }}
                  >
                    <p>{`${item.firstName} ${item.lastName}`}</p>
                    {sent.includes(item.id) ? (
                      <p
                        style={{
                          fontStyle: "italic",
                          opacity: 0.7,
                          width: 60,
                          textAlign: "center",
                        }}
                      >
                        sent
                      </p>
                    ) : (
                      <Button variant="secondary" onClick={() => send(item)}>
                        Send
                      </Button>
                    )}
                  </div>
                );
              })}
              {sortedFriends().filter((item) => !sent.includes(item.id))
                .length === 0 ? (
                <p>No friends available.</p>
              ) : (
                <div />
              )}
            </div>
          )}
        </Modal.Body>
      ) : (
        <div />
      )}
      {props.board ? (
        <Modal.Footer>
          <Button
            onClick={() => {
              props.onHide();
              setSent([]);
            }}
          >
            Done
          </Button>
        </Modal.Footer>
      ) : (
        <div />
      )}
    </Modal>
  );
}

const enhance = compose(withFirestore);

export default enhance(ShareScholarshipModal);
