/*****************************************************************************
 * Import
 *****************************************************************************/
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { Flags } from "models/Flags";

/*****************************************************************************
 * Public Hooks
 *****************************************************************************/

export const useFlags = (): Flags => {
  useFirestoreConnect([
    {
      collection: "mode",
      doc: "flags",
    },
  ]);

  const mode = useSelector((state: any) => state.firestore.data.mode);

  if (mode?.flags) {
    return {
      ...mode.flags,
      isLoaded: true,
    }
  } else {
    return {
      isLoaded: false
    } as Flags;
  }

}
