/*****************************************************************************
 * Import
 *****************************************************************************/
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMe } from "../hooks/users";
import { useFirestoreConnect } from "react-redux-firebase";

/*****************************************************************************
 * Hook
 *****************************************************************************/

export const useFlagMode = (): "pbr_disabled" | "pg_disabled" | "both" | "none" => {
  const [flagMode, setFlagMode] = useState<"pbr_disabled" | "pg_disabled" | "both" | "none">("none");
  
  const mode = useSelector((state: any) => state.firestore.data.mode);
  const me = useMe();

  useFirestoreConnect([
    {
      collection: "mode",
      doc: "flags",
    },
  ]);

  useEffect(() => {
    setFlagMode(calcFlagMode(me, mode));
  }, [mode, me]);

  return flagMode;
}

/*****************************************************************************
 * Helper Functions
 *****************************************************************************/
const calcFlagMode = (user, mode): "pbr_disabled" | "pg_disabled" | "both" | "none" => {
  let hasPrepCreds = false;
  let hasPGCreds = false;
  //@ts-ignore
  let creds = user ? user.credentials : null;

  if (creds && creds.pg && creds.pg.approved) {
    hasPGCreds = true;
  }
  if (creds && creds.prep && creds.prep.approved) {
    hasPrepCreds = true;
  }
  let hasBoth = hasPGCreds && hasPrepCreds;
  let hasNone = !hasPGCreds && !hasPrepCreds;
  if (hasBoth) {
    return "none";
  }
  if (mode && mode.flags) {
    if (mode.flags.disable_pbr_rankings || mode.flags.disable_pg_rankings) {
      if (
        hasNone &&
        mode.flags.disable_pbr_rankings &&
        mode.flags.disable_pg_rankings
      ) {
        return "both";
      } else if (!hasPrepCreds && mode.flags.disable_pbr_rankings) {
        return "pbr_disabled";
      } else if (!hasPGCreds && mode.flags.disable_pg_rankings) {
        return "pg_disabled";
      }
    }
  }

  return "both";
};
