import colors from "constants/colors";
import React from "react";
import { Link } from "react-router-dom";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { useSelector } from "react-redux";

function FriendName(props: {
  friend: any;
  background?: string;
  blueAvatar?: boolean;
  containerStyles?: any;
  left?: boolean;
  hover?: boolean;
  setShowRemoveFriend?: any;
  setShowRevokeAccess?: any;
}) {
  const profile = useSelector((state: any) => state.firebase.profile);

  return (
    <OverlayTrigger
      trigger={props.hover ? ["hover", "focus"] : "focus"}
      placement={props.left ? "left" : "right"}
      overlay={
        <Popover id={`popover-positioned-right`}>
          <Popover.Title
            as="h3"
            style={{
              backgroundColor: colors.accent,
              color: colors.white,
              textAlign: "center",
            }}
          >
            {`${props.friend.firstName} ${props.friend.lastName}`}
            <p style={{ textAlign: "center" }}>{props.friend.email}</p>
          </Popover.Title>
          <Popover.Content>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                minWidth: 250,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <p style={{ fontWeight: 800, color: colors.accent }}>
                  Affiliated Team
                </p>
                <p>{props.friend.affiliatedTeam}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <p style={{ fontWeight: 800, color: colors.accent }}>College</p>
                <p>{props.friend.college ? props.friend.college : "N/A"}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <p style={{ fontWeight: 800, color: colors.accent }}>Title</p>
                <p>{props.friend.title ? props.friend.title : "N/A"}</p>
              </div>
              {props.hover ? (
                <div />
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 40,
                  }}
                >
                  <Button
                    variant="secondary"
                    style={{
                      borderColor: colors.accent,
                      fontWeight: 800,
                      fontSize: 15,
                      color: colors.accent,
                      flex: 1,
                    }}
                    onClick={() => {
                      if (props.setShowRemoveFriend !== null) {
                        props.setShowRemoveFriend(props.friend);
                      }
                    }}
                  >
                    REMOVE FRIEND
                  </Button>
                </div>
              )}
              {(profile.sharedBoards || []).includes(props.friend.id) &&
              !props.hover ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: 5,
                  }}
                >
                  <Button
                    variant="secondary"
                    style={{
                      borderColor: colors.accent,
                      fontWeight: 800,
                      fontSize: 15,
                      color: colors.accent,
                    }}
                    onClick={() => {
                      if (props.setShowRevokeAccess !== null) {
                        props.setShowRevokeAccess(props.friend);
                      }
                    }}
                  >
                    REVOKE BIG BOARD ACCESS
                  </Button>
                </div>
              ) : (
                <div />
              )}
            </div>
          </Popover.Content>
        </Popover>
      }
    >
      <div
        style={{
          ...props.containerStyles,
          display: "flex",
          flexDirection: "row",

          borderStyle: "solid",
          borderRadius: 9,

          alignItems: "center",
        }}
      >
        <div
          style={{
            width: 35,
            height: 35,
            backgroundColor: props.blueAvatar ? "#003366" : "#fff",
            borderRadius: 35 / 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              color: props.blueAvatar ? "#fff" : "#003366",
              textAlign: "center",
              fontWeight: 600,
            }}
          >
            {`${
              props.friend.firstName
                ? props.friend.firstName.charAt(0).toUpperCase()
                : ""
            }${
              props.friend.lastName
                ? props.friend.lastName.charAt(0).toUpperCase()
                : ""
            }`}
          </p>
        </div>
        <Link
          to={`/settings`}
          style={{
            marginLeft: 8,
            color: colors.accent,
          }}
        >
          {`${props.friend.firstName} ${props.friend.lastName}`}
        </Link>
      </div>
    </OverlayTrigger>
  );
}

export default FriendName;
