import { Player } from "../models/Player";
import moment from "moment";
// import { User } from "../models/User";
import { QueryHelper } from "./QueryHelper";
import { CollegePlayer } from "models/CollegePlayer";
import { FBAnalytics } from "store/store";
import playerPage from "pages/player-page/player-page";

export class PlayerService {
  static getScholarship = (
    player: Player,
    year: string,
    kind: "both" | "schol" | "finAid",
    ignoreRedShirt?: boolean
  ) => {
    return ignoreRedShirt &&
      PlayerService.redShirtYears(player).includes(parseInt(year))
      ? 0
      : kind === "both"
      ? (player.scholarshipByYear?.[year] || 0) +
        (player.financialAidByYear?.[year] || 0)
      : kind === "schol"
      ? player.scholarshipByYear?.[year] || 0
      : player.financialAidByYear?.[year] || 0;
  };

  static getName = (player: Player | CollegePlayer) => {
    if (player) {
      let name = player.name
        ? player.name
        : player.first_name && player.first_name.length > 0
        ? `${player.first_name} ${player.last_name}`
        : player["﻿name"]
        ? player["﻿name"]
        : "";

      return PlayerService.titleCase(name);
    }
    return "";
  };

  static getPosition = (player: Player | CollegePlayer) => {
    if (!player) {
      return "N/A";
    }
    
    if (player.college && !player.juco) {
      return {
        "Infield": "IF",
        "Outfield": "OF",
        "Pitcher": "P",
        "Catcher": "C",
        "Utility": "UT",
        "First Base": "1B",
        "Second Base": "2B",
        "Third Base": "3B",
        "Shortstop": "SS",
      }[player.position] || player.position || "N/A";
    } else {
      player = player as Player;

      let pos = player.primary_position
        ? player.alt_position
          ? `${player.primary_position}, ${player.alt_position}`
          : `${player.primary_position}`
        : player.position || "N/A";

      pos = pos === "N/A" ? pos
        : pos.split('/').join(", ")

      return {
        "UTIL": "UT",
      }[pos] || pos;
    }
  };

  static getStartYear = (player: Player | CollegePlayer) => {
    let thisYear = parseInt(`${moment().year()}`);
    if (player) {
      //@ts-ignore
      if (player.startYear) {
        //@ts-ignore
        return parseInt(`${player.startYear}`);
      }
      if (player.college) {
        //@ts-ignore
        let classYear = player.academic_class;
        let isSpringSemester = moment().month() < 7;
        let subtractAmount = isSpringSemester ? 1 : 0;

        switch (classYear) {
          case "Freshman":
          case "Fr":
          case "R-Freshman":
            return thisYear - subtractAmount;
          case "Sophomore":
          case "So":
            return thisYear - 1 - subtractAmount;
          case "Junior":
          case "Ju":
          case "Jr":
            return thisYear - 2 - subtractAmount;
          case "Senior":
          case "Se":
          case "Sr":
            return thisYear - 3 - subtractAmount;
        }
      }
      //@ts-ignore
      return parseInt(player.graduating_class);
    }
    return thisYear;
  };

  static getEndYear = (player: Player | CollegePlayer) => {
    //@ts-ignore
    if (player.endYear) {
      //@ts-ignore
      return parseInt(`${player.endYear}`);
    }
    let start = PlayerService.getStartYear(player);
    //@ts-ignore
    if (player && player.redShirt && player.redShirt !== "none") {
      if (((player as any).redShirtYears || []).length > 1) {
        return start + 3 + (player as any).redShirtYears.length;
      } else {
        return start + 4;
      }
    }
    return start + 3;
  };

  static getGraduatingYear = (player: Player | CollegePlayer) => {
    if (player.college) {
      return PlayerService.getEndYear(player) || "N/A";
    } else {
      return (player as Player).graduating_class || "N/A";
    }
  }

  static redShirtYears = (player: Player) => {
    return player.redShirtYear
      ? [...(player.redShirtYears || []), parseInt(`${player.redShirtYear}`)]
      : [...(player.redShirtYears || [])];
  };

  static titleCase = (str: string) => {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  };

  static getServerPhoto = (player: any) => {
    if (player.college) {
      return null;
    }
    return player && player.image_url && player.image_url !== ""
      ? player.image_url
      : player && player.pg_image_url && player.pg_image_url !== ""
      ? player.pg_image_url
      : player && player.pbr_image_url && player.pbr_image_url !== ""
      ? player.pbr_image_url
      : null;
  };

  static isSaved = (playerIds: string[], playerId: string) => {
    if (playerId) {
      if ((playerIds || []).includes(playerId)) {
        return true;
      }
    }
    return false;
  };

  static exists = (inactivePlayerIds: string[], playerId: string) => {
    if (playerId) {
      let playerIds = inactivePlayerIds;
      if ((playerIds || []).includes(playerId)) {
        return true;
      }
    }
    return false;
  };

  static getInitials = (player: Player | CollegePlayer) => {
    let name = PlayerService.getName(player);
    if (name) {
      let names = name.split(" ");
      if (names.length > 0) {
        return `${names[0].charAt(0).toUpperCase()}${names[1]
          .charAt(0)
          .toUpperCase()}`;
      }
    }
    return "";
  };

  static updateRosterPlayerOrdering = async (
    userId: string,
    player: Player,
    order: number,
    firestore: any
  ) => {
    if (player && player.external_id) {
      const docRefConfig = {
        collection: "users",
        doc: userId,
        subcollections: [
          { collection: "roster", doc: `${player.external_id}` },
        ],
      };

      await firestore.update(docRefConfig, {
        rosterNumber: order,
      });
    }
  };

  static updateRosterOrdering = async (
    userId: string,
    players: Player[],
    from: number,
    to: number,
    firestore: any
  ) => {
    let movingUp = from > to;

    let numbers = players.map((item) => item.rosterNumber);
    console.log("numbers", numbers);

    await PlayerService.updateRosterPlayerOrdering(
      userId,
      players[from],
      players[to].rosterNumber,
      firestore
    );
    if (movingUp) {
      for (let i = to; i < from; i++) {
        await PlayerService.updateRosterPlayerOrdering(
          userId,
          players[i],
          numbers[i + 1],
          firestore
        );
      }
    } else {
      for (let i = from + 1; i < to + 1; i++) {
        await PlayerService.updateRosterPlayerOrdering(
          userId,
          players[i],
          numbers[i - 1],
          firestore
        );
      }
    }
  };

  static updatePlayerOrdering = async (
    userId: string,
    player: Player,
    order: number,
    boardId: string,
    firestore: any
  ) => {
    const docRefConfig = {
      collection: "users",
      doc: userId,
      subcollections: [
        { collection: "prospects", doc: `${player.external_id}` },
      ],
    };

    const boardRefConfig = {
      collection: "boards",
      doc: boardId,
      subcollections: [
        { collection: "prospects", doc: `${player.external_id}` },
      ],
    };

    const shouldUsePlayerOrdering = boardId == userId || !player.ordering;

    var newCustom = {
      [boardId]: order,
    };
    var custom = { ...(player.customOrdering || {}), ...newCustom };

    try {
      await firestore.update(docRefConfig, {
        ordering: shouldUsePlayerOrdering ? order : player.ordering,
        customOrdering: custom,
      });
    } catch (err) {
      // oh well
    }

    await firestore.update(boardRefConfig, {
      ordering: shouldUsePlayerOrdering ? order : player.ordering,
      customOrdering: custom,
    });
  };

  static updateOrdering = async (
    userId: string,
    players: Player[],
    from: number,
    to: number,
    boardId: string,
    firestore: any
  ) => {
    let movingUp = from > to;

    const diff = to - from;
    await PlayerService.updatePlayerOrdering(
      userId,
      players[from],
      players[to].ordering,
      boardId,
      firestore
    );
    if (movingUp) {
      for (let i = to; i < from; i++) {
        await PlayerService.updatePlayerOrdering(
          userId,
          players[i],
          players[i].ordering + 1,
          boardId,
          firestore
        );
      }
    } else {
      for (let i = from + 1; i < to + 1; i++) {
        await PlayerService.updatePlayerOrdering(
          userId,
          players[i],
          players[i].ordering - 1,
          boardId,
          firestore
        );
      }
    }
  };

  static logSaveEvent = (
    player: Player | CollegePlayer,
    numSaved: number,
    adding: boolean
  ) => {
    FBAnalytics.setUserProperties({
      num_saved_prospects: `${adding ? numSaved + 1 : numSaved - 1}`,
    });
    if (adding) {
      FBAnalytics.logEvent("save_prospect", {
        name: PlayerService.getName(player),
        id: player.external_id,
        //@ts-ignore
        position: player.college ? player.position : player.primary_position,
      });
    }
  };

  static toggleSaved = async (
    firestore: any,
    userId: any,
    playerIds: string[],
    inactivePlayerIds: string[],
    player: Player | CollegePlayer | null,
    playerId?: string
  ) => {
    let ext_id = player ? player.external_id : playerId;
    const docRefConfig = {
      collection: "users",
      doc: userId,
      subcollections: [{ collection: "prospects", doc: `${ext_id}` }],
    };

    if (PlayerService.isSaved(playerIds, ext_id)) {
      await firestore.update(docRefConfig, {
        active: false,
        lastViewed: new Date(),
      });
      PlayerService.logSaveEvent(null, (playerIds || []).length, false);
    } else {
      if (PlayerService.exists(inactivePlayerIds, ext_id)) {
        await firestore.update(docRefConfig, {
          active: true,
          lastViewed: new Date(),
        });
      } else {
        if (player) {
          await firestore.set(docRefConfig, {
            ...player,
            ordering:
              (inactivePlayerIds || []).length + (playerIds || []).length,
            active: true,
            lastViewed: new Date(),
            notes: "",
            photos: PlayerService.getServerPhoto(player)
              ? //@ts-ignore
                [...(player.photos || []), PlayerService.getServerPhoto(player)]
              : //@ts-ignore
                [...(player.photos || [])],
          });
          PlayerService.logSaveEvent(player, (playerIds || []).length, true);
        } else {
          let p = await QueryHelper.getPlayerForExternalId(ext_id);
          if (p && p.data && p.data.length > 0) {
            let players = p.data.filter((item) => item.external_id === ext_id);
            if (players.length > 0) {
              let fetchedPlayer = players[0];
              await firestore.set(docRefConfig, {
                ...fetchedPlayer,
                ordering:
                  (inactivePlayerIds || []).length + (playerIds || []).length,
                active: true,
                notes: "",
                lastViewed: new Date(),
                photos: PlayerService.getServerPhoto(fetchedPlayer)
                  ? [
                      ...(fetchedPlayer.photos || []),
                      PlayerService.getServerPhoto(fetchedPlayer),
                    ]
                  : [...(fetchedPlayer.photos || [])],
              });
              PlayerService.logSaveEvent(
                fetchedPlayer,
                (playerIds || []).length,
                true
              );
            }
          }
        }
      }
    }
  };
}
