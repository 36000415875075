import colors from "constants/colors";
import React, { useState } from "react";
import { compose } from "recompose";
import {
  isLoaded,
  withFirestore,
  useFirestoreConnect,
} from "react-redux-firebase";
import { useSelector } from "react-redux";
import { Button, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
// import { User } from "models/User";
import { NotificationService } from "services/NotificationService";

function CreateRequestModal(props: {
  firestore: any;
  onHide: any;
  friends: any[];
  pendingRequests: any[];
  show: any;
  me: any;
}) {
  const [foundUser, setFoundUser] = useState<any | null>(null);
  const [messageString, setMessageString] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const searchForFriend = async (email: string) => {
    if (email.length > 4) {
      setLoading(true);
      let res = await props.firestore.get({
        collection: "users",
        where: ["email", "==", email.toLowerCase()],
        storeAs: "searchedUsers",
      });
      setLoading(false);
      let found: any[] = [];
      (res || []).forEach((item) => {
        found.push({ ...item.data(), id: item.id });
      });
      if (found.length > 0) {
        let user = found[0];

        if (
          props.friends.filter((item) => item.email === email.toLowerCase())
            .length > 0
        ) {
          toast.dark(`You are already friends with ${user.email}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
          });
          close();

          return;
        } else if (
          (props.pendingRequests || []).filter(
            (item) => item.recipientEmail === email.toLowerCase()
          ).length > 0
        ) {
          toast.dark(`You have already sent a request to ${user.email}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
          });
          close();

          return;
        } else {
          setFoundUser(user);
          setMessageString(
            `Add ${user.firstName} ${user.lastName} as a friend?`
          );
        }
      } else {
        setMessageString(
          `We can't find that user. Send an invitation to ${email} to join Scout?`
        );
      }
    }
  };

  const addFriend = () => {
    setLoading(true);
    //@ts-ignore
    NotificationService.addFriend(foundUser, props.me, props.firestore);
    toast.dark(`Request sent!`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });
    setLoading(false);
    close();
  };

  const inviteFriend = () => {
    props.firestore.add(
      { collection: "mail" },
      {
        to: email,
        message: {
          subject: `${props.me.firstName} ${props.me.lastName} invited you to join Scout`,
          text: "Click here to join.",
          // NOTE: if the invitation join URL is not right, this is where it needs to be updated
          html: `<p>Click <a href="https://apps.apple.com/us/app/scout-net/id1495595789">here</a> to join.</p>`,
        },
      }
    );

    toast.dark(`Invitation sent!`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });
    close();
  };

  const close = () => {
    setFoundUser(null);
    setMessageString("");
    setEmail("");
    setLoading(false);
    props.onHide();
  };

  return (
    <Modal
      {...props}
      //@ts-ignore
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          borderBottomWidth: 0,
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          Add a Friend
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          display: "flex",
          flexDirection: "column",
        }}
        className="searching"
      >
        {loading ? (
          <Spinner animation="border" />
        ) : messageString !== "" ? (
          <p>{messageString}</p>
        ) : (
          <input
            type="text"
            style={{
              marginTop: 20,
              marginBottom: 10,
              display: "flex",
              flex: 1,
              border: "0px solid",
              color: "#fff",
              backgroundColor: "transparent",
              borderBottom: "2px solid #fff",
            }}
            placeholder="Enter Email Address"
            onChange={(evt) => setEmail(evt.target.value)}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => close()}
          style={{
            borderColor: colors.accent,
            backgroundColor: colors.white,
            color: colors.accent,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (foundUser) {
              addFriend();
            } else if (messageString) {
              inviteFriend();
            } else {
              searchForFriend(email);
            }
          }}
        >
          Add Friend
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const enhance = compose(withFirestore);

export default enhance(CreateRequestModal);
