import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import colors from "constants/colors";
import React from "react";
import Select from "react-select";
import { PlayerService } from "services/PlayerService";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    minWidth: 250,
    maxWidth: 320,
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: colors.lightBlue,
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: colors.white,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: colors.white,
    ":hover": {
      backgroundColor: colors.accent,
    },
  }),
};

const ProspectDropdown = ({ selectProspect }) => {
  const userId = useSelector((state: any) => state.firebase.auth.uid);
  const myProspects = useSelector((state: any) => state.firestore.data.myProspects);
  useFirestoreConnect([
    {
      collection: "users",
      doc: userId,
      subcollections: [
        { collection: "prospects", where: ["active", "==", true] },
      ],
      storeAs: "myProspects",
    },
  ]);

  if (!isLoaded(myProspects)) {
    return (
      <div>
        <p>loading...</p>
      </div>
    );
  }

  const sortedProspects = () => {
    let prospects = myProspects
      ? Object.keys(myProspects).map((key) =>
          myProspects ? { ...myProspects[key], id: key } : null
        )
      : [];

    return prospects.filter((item) => item !== null && item.active);
  };

  const onChange = (item) => {
    selectProspect(item.value);
  };

  return sortedProspects().length > 0 ? (
    <Select
      options={sortedProspects().map((item) => ({
        label: PlayerService.getName(item),
        value: item,
      }))}
      isMulti={false}
      isClearable={false}
      onChange={onChange}
      styles={customStyles}
      placeholder="Select..."
    />
  ) : (
    <div>
      <p>No prospects.</p>
    </div>
  );
};

export default ProspectDropdown;
