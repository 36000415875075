import { PositionReport } from "models/PositionReport";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { compose } from "recompose";
import {
  isLoaded,
  withFirestore,
  useFirestoreConnect,
} from "react-redux-firebase";
import PositionReportForm from "./forms/position-report-form";
import { useSelector } from "react-redux";
import "./reports.css";
import PitcherReportForm from "./forms/pitcher-report-form";
import GeneralReportForm from "./forms/general-report-form";
import NotesReportForm from "./forms/notes-report-form";
import { useHistory } from "react-router-dom";
import LoadingView from "shared/loading-view";
import CTALink from "shared/cta-link";

function EditReport(props: any) {
  let { reportId } = useParams();
  let key = `currentReport-${reportId}`;
  const userId = useSelector((state: any) => state.firebase.auth.uid);
  const [posReport, setPosReport] = useState<PositionReport | null>(null);
  const currentReport: any = useSelector<PositionReport>((state: any) =>
    Object.keys(state.firestore.data).includes(key)
      ? state.firestore.data[key]
      : null
  );
  let history = useHistory();

  useFirestoreConnect([
    {
      collection: "users",
      doc: userId,
      subcollections: [{ collection: "reports", doc: reportId }],
      storeAs: key,
    },
  ]);

  const [versions, setVersions] = useState<PositionReport[]>([]);

  if (!isLoaded(currentReport) || !currentReport) {
    return <LoadingView />;
  } else if (posReport === null) {
    setPosReport(currentReport);
  }

  const saveReport = async (report) => {
    const boardsDocRefConfig = {
      collection: "users",
      doc: userId,
      subcollections: [{ collection: "reports", doc: reportId }],
    };
    await props.firestore.update(boardsDocRefConfig, {
      ...report,
      lastUpdated: new Date(),
    });
  };

  const calculatedOFP = (report: PositionReport) => {
    if (currentReport.kind === "PITCHER") {
      return calculatedPitcherOFP(report);
    }
    if (currentReport.kind === "PRO") {
      return calculatedProOFP(report);
    }
    return {
      p: "0",
      f: "0",
      adj: "0",
    };
  };

  const calculatedProOFP = (report: PositionReport) => {
    let pTotal = 0;
    let pn = 0;
    if (report.hittingAbility.p !== "") {
      pTotal = pTotal + parseInt(report.hittingAbility.p);
      pn = pn + 1;
    }
    if (report.rawPower.p !== "") {
      pTotal = pTotal + parseInt(report.rawPower.p);
      pn = pn + 1;
    }
    if (report.runningAbility.p !== "") {
      pTotal = pTotal + parseInt(report.runningAbility.p);
      pn = pn + 1;
    }
    if (report.armStrength.p !== "") {
      pTotal = pTotal + parseInt(report.armStrength.p);
      pn = pn + 1;
    }
    if (report.fieldingAbility.p !== "") {
      pTotal = pTotal + parseInt(report.fieldingAbility.p);
      pn = pn + 1;
    }
    if (report.canHePlay.p !== "") {
      pTotal = pTotal + parseInt(report.canHePlay.p);
      pn = pn + 1;
    }
    if (pn > 0) {
      pTotal = Math.floor((pTotal * 10) / pn);
    } else {
      pTotal = 0;
    }

    let fTotal = 0;
    let fn = 0;
    if (report.hittingAbility.f !== "") {
      fTotal = fTotal + parseInt(report.hittingAbility.f);
      fn = fn + 1;
    }
    if (report.rawPower.f !== "") {
      fTotal = fTotal + parseInt(report.rawPower.f);
      fn = fn + 1;
    }
    if (report.runningAbility.f !== "") {
      fTotal = fTotal + parseInt(report.runningAbility.f);
      fn = fn + 1;
    }
    if (report.armStrength.f !== "") {
      fTotal = fTotal + parseInt(report.armStrength.f);
      fn = fn + 1;
    }
    if (report.fieldingAbility.f !== "") {
      fTotal = fTotal + parseInt(report.fieldingAbility.f);
      fn = fn + 1;
    }
    if (report.canHePlay.f !== "") {
      fTotal = fTotal + parseInt(report.canHePlay.f);
      fn = fn + 1;
    }
    if (fn > 0) {
      fTotal = Math.floor((fTotal * 10) / fn);
    } else {
      fTotal = 0;
    }

    let adjTotal = 0;
    let adjn = 0;
    if (report.hittingAbility.adj !== "") {
      adjTotal = adjTotal + parseInt(report.hittingAbility.adj);
      adjn = adjn + 1;
    }
    if (report.rawPower.adj !== "") {
      adjTotal = adjTotal + parseInt(report.rawPower.adj);
      adjn = adjn + 1;
    }
    if (report.runningAbility.adj !== "") {
      adjTotal = adjTotal + parseInt(report.runningAbility.adj);
      adjn = adjn + 1;
    }
    if (report.armStrength.adj !== "") {
      adjTotal = adjTotal + parseInt(report.armStrength.adj);
      adjn = adjn + 1;
    }
    if (report.fieldingAbility.adj !== "") {
      adjTotal = adjTotal + parseInt(report.fieldingAbility.adj);
      adjn = adjn + 1;
    }
    if (report.canHePlay.adj !== "") {
      adjTotal = adjTotal + parseInt(report.canHePlay.adj);
      adjn = adjn + 1;
    }
    if (adjn > 0) {
      adjTotal = Math.floor((adjTotal * 10) / adjn);
    } else {
      adjTotal = 0;
    }

    return {
      p: `${pTotal}`,
      f: `${fTotal}`,
      adj: `${adjTotal}`,
    };
  };

  const calculatedPitcherOFP = (report: PositionReport) => {
    let pTotal = 0;
    let pn = 0;
    if (report.fB.p !== "") {
      pTotal = pTotal + parseInt(report.fB.p);
      pn = pn + 1;
    }
    if (report.sL.p !== "") {
      pTotal = pTotal + parseInt(report.sL.p);
      pn = pn + 1;
    }
    if (report.cB.p !== "") {
      pTotal = pTotal + parseInt(report.cB.p);
      pn = pn + 1;
    }
    if (report.cH.p !== "") {
      pTotal = pTotal + parseInt(report.cH.p);
      pn = pn + 1;
    }
    if (report.command.p !== "") {
      pTotal = pTotal + parseInt(report.command.p);
      pn = pn + 1;
    }
    if (report.canHePitch.p !== "") {
      pTotal = pTotal + parseInt(report.canHePitch.p);
      pn = pn + 1;
    }
    if (pn > 0) {
      pTotal = Math.floor((pTotal * 10) / pn);
    } else {
      pTotal = 0;
    }

    let fTotal = 0;
    let fn = 0;
    if (report.fB.f !== "") {
      fTotal = fTotal + parseInt(report.fB.f);
      fn = fn + 1;
    }
    if (report.sL.f !== "") {
      fTotal = fTotal + parseInt(report.sL.f);
      fn = fn + 1;
    }
    if (report.cB.f !== "") {
      fTotal = fTotal + parseInt(report.cB.f);
      fn = fn + 1;
    }
    if (report.cH.f !== "") {
      fTotal = fTotal + parseInt(report.cH.f);
      fn = fn + 1;
    }
    if (report.command.f !== "") {
      fTotal = fTotal + parseInt(report.command.f);
      fn = fn + 1;
    }
    if (report.canHePitch.f !== "") {
      fTotal = fTotal + parseInt(report.canHePitch.f);
      fn = fn + 1;
    }
    if (fn > 0) {
      fTotal = Math.floor((fTotal * 10) / fn);
    } else {
      fTotal = 0;
    }

    let adjTotal = 0;
    let adjn = 0;
    if (report.fB.adj !== "") {
      adjTotal = adjTotal + parseInt(report.fB.adj);
      adjn = adjn + 1;
    }
    if (report.sL.adj !== "") {
      adjTotal = adjTotal + parseInt(report.sL.adj);
      adjn = adjn + 1;
    }
    if (report.cB.adj !== "") {
      adjTotal = adjTotal + parseInt(report.cB.adj);
      adjn = adjn + 1;
    }
    if (report.cH.adj !== "") {
      adjTotal = adjTotal + parseInt(report.cH.adj);
      adjn = adjn + 1;
    }
    if (report.command.adj !== "") {
      adjTotal = adjTotal + parseInt(report.command.adj);
      adjn = adjn + 1;
    }
    if (report.canHePitch.adj !== "") {
      adjTotal = adjTotal + parseInt(report.canHePitch.adj);
      adjn = adjn + 1;
    }
    if (adjn > 0) {
      adjTotal = Math.floor((adjTotal * 10) / adjn);
    } else {
      adjTotal = 0;
    }

    return {
      p: `${pTotal}`,
      f: `${fTotal}`,
      adj: `${adjTotal}`,
    };
  };

  const setReport = (report: PositionReport) => {
    let updatedRep = { ...report, ofp: calculatedOFP(report) };
    //@ts-ignore
    setPosReport(updatedRep);
    //@ts-ignore
    setVersions([...versions, posReport]);
    saveReport(updatedRep);
  };

  const deleteReport = async () => {
    const boardsDocRefConfig = {
      collection: "users",
      doc: userId,
      subcollections: [{ collection: "reports", doc: reportId }],
    };
    await props.firestore.delete(boardsDocRefConfig, currentReport);
    history.replace("/reports");
  };

  const createPositionReport = () => {
    if (currentReport.kind === "PRO") {
      return (
        <PositionReportForm
          posReport={posReport}
          setPosReport={setReport}
          deleteReport={deleteReport}
        />
      );
    }
    return <div />;
  };

  const createPitcherReport = () => {
    if (currentReport.kind === "PITCHER") {
      return (
        <PitcherReportForm
          posReport={posReport}
          setPosReport={setReport}
          deleteReport={deleteReport}
        />
      );
    }
    return <div />;
  };

  const createGeneralReport = () => {
    if (currentReport.kind === "GENERAL") {
      return (
        <GeneralReportForm
          posReport={posReport}
          setPosReport={setReport}
          deleteReport={deleteReport}
        />
      );
    }
    return <div />;
  };

  const createNotes = () => {
    if (currentReport.kind === "NOTES") {
      return (
        <NotesReportForm
          posReport={posReport}
          setPosReport={setReport}
          deleteReport={deleteReport}
        />
      );
    }
    return <div />;
  };

  return (
    <div
      style={{
        paddingLeft: "1rem",
        margin: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h2 style={{ fontFamily: "Raleway" }}>Create Report</h2>
      <p style={{ marginRight: 8, paddingTop: 6 }}>
        {currentReport ? currentReport.playerName : ""}
      </p>

      {createPositionReport()}
      {createPitcherReport()}
      {createGeneralReport()}
      {createNotes()}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: -15,
          marginBottom: 30,
        }}
      >
        <CTALink
          text="DONE"
          linkTo={`/display-report/${reportId}`}
          styles={{}}
        />
      </div>
    </div>
  );
}

const enhance = compose(withFirestore);

export default enhance(EditReport);
