import { useMe } from "../../hooks/users";
import colors from "constants/colors";
import { MonitorDocument } from "models/MonitorDocument";
import { MonitorQuery } from "models/MonitorQuery";
// import { User } from "models/User";
import qs from "qs";
import React, { useEffect, useRef, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { AiOutlineSearch } from "react-icons/ai";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { useHistory, useLocation, Link } from "react-router-dom";
import { basicSearch, createUser } from "services/MonitorService";
import CTAButton from "shared/cta-button";
import ScoutTitle from "shared/scout-title";
import DocumentItem from "./components/doc-item";
import LeftFilters from "./left-filters";
import RightFilters from "./right-filters";
import { getSources } from "services/MonitorService";
import UnderlineButton from "shared/underline-button";
import { useSelector } from "react-redux";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function MonitorPage(props: any) {
  let location = useLocation();
  let history = useHistory();
  const [sources, setSources] = useState<
    {
      active: boolean;

      guid: string;
      name: string;
    }[]
  >([]);
  const [results, setResults] = useState<MonitorDocument[]>([]);
  const [count, setCount] = useState(0);
  const [monitorQuery, setMonitorQuery] = useState<MonitorQuery>({
    text: "",
    all: [],
    any: [],
    none: [],
    source: [],
    daysBack: 60,
  });
  const [nextOffset, setNextOffset] = useState(0);
  const [stoplightId, setStoplightId] = useState(null);
  const me = useMe();
  const firestore = useFirestore();
  const prevId = usePrevious(stoplightId);
  const [loading, setLoading] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(true);
  const [hasSearched, setHasSearched] = useState(false);
  const myDiscoveries = useSelector(
    (state: any) => state.firestore.data.myDiscoveries
  );
  const myProspects = useSelector(
    (state: any) => state.firestore.data.myProspects
  );

  useFirestoreConnect([
    {
      collection: "users",
      doc: me?.id as unknown as string,
      subcollections: [{ collection: "discoveries" }],
      storeAs: `myDiscoveries`,
    },
    {
      collection: "users",
      doc: me?.id as unknown as string,
      subcollections: [
        { collection: "prospects", where: ["active", "==", true] },
      ],
      storeAs: "myProspects",
    },
  ]);

  const sortedDiscoveries = () => {
    let events = myDiscoveries
      ? Object.keys(myDiscoveries).map((key) =>
          myDiscoveries ? { ...myDiscoveries[key], id: key } : null
        )
      : [];

    return events.filter((item) => item !== null && item.term);
  };

  const sortedProspects = () => {
    let prospects = myProspects
      ? Object.keys(myProspects).map((key) =>
          myProspects ? { ...myProspects[key], id: key } : null
        )
      : [];

    return prospects.filter((item) => item !== null && item.active);
  };

  useEffect(() => {
    fetchDataForURLParams();
  });

  useEffect(() => {
    fetchSources();
  }, []);

  const fetchSources = async () => {
    // tara later
    // let res = await getSources();
    // let sourceArray = Object.keys(res).map((key) => res[key]);
    let sourceArray = [
      {
        active: true,
        guid: "fe975e740116cd7b90c5da74f58a6c9c3527898899efe6213d4551de6bf80e5d",
        name: "Twitter",
      },
      {
        guid: "7e0442f5a0674357aaa7ec3fb932f672",
        active: true,
        name: "Google",
      },
    ];
    setSources(sourceArray);
    checkUser();
  };

  useEffect(() => {
    history.listen((location, action) => {
      setHasSearched(false);
    });
  }, []);

  useEffect(() => {
    if (stoplightId && prevId == null) {
      if (loading) {
        search(monitorQuery);
      } else {
        search({ ...monitorQuery, source: sources });
      }
    }
  }, [stoplightId]);

  const checkUser = () => {
    if (me.stoplightId) {
      setStoplightId(me.stoplightId);
    } else {
      createStoplightUser();
    }
  };

  const createStoplightUser = async () => {
    setLoading(true);
    let res = await createUser(me);
    setLoading(false);
    if (res && res.guid) {
      await firestore.collection("users").doc(me.id as unknown as string).update({
        stoplightId: res.guid,
      });
      setStoplightId(me.stoplightId);
    }
  };

  const reset = () => {
    setResults([]);
    setCount(0);
    setLoading(false);
  };

  const fetchDataForURLParams = async () => {
    if (!hasSearched) {
      const data = location.search;
      const params = qs.parse(data, { ignoreQueryPrefix: true });

      let shouldSearch = false;

      if (params && Object.keys(params).length > 0) {
        reset();
        let query: MonitorQuery = {
          any: [],
          all: [],
          none: [],
          source: [],
        };

        if (params.text) {
          query = {
            ...query,
            text: params.text as string,
          };
          shouldSearch = true;
        }
        if (params.all) {
          query = {
            ...query,
            all: (params.all as string).split(","),
          };

          shouldSearch = true;
        }

        if (params.any) {
          query = {
            ...query,
            any: (params.any as string).split(","),
          };

          shouldSearch = true;
        }
        if (params.none) {
          query = {
            ...query,
            none: (params.none as string).split(","),
          };

          shouldSearch = true;
        }

        if (params.daysBack) {
          query = {
            ...query,
            daysBack: parseInt(params.daysBack as string),
          };

          shouldSearch = true;
        }

        if (params.source) {
          query = {
            ...query,
            source: (params.source as string).split(",").map((item) => {
              let vals = item.split("_");
              return {
                guid: vals[0],
                name: vals.length > 1 ? vals[1] : "Source",
                active: true, //!blw: idk
              };
            }),
          };

          shouldSearch = true;
        }

        if (shouldSearch) {
          setHasSearched(true);

          search(query);
        } else {
          setHasSearched(true);
        }
      } else {
        setHasSearched(true);
      }
    }
  };

  const search = async (query: MonitorQuery, offset?: number) => {
    setMonitorQuery(query);
    setLoading(true);
    if (stoplightId) {
      try {
        let res = await basicSearch(stoplightId, query, offset || 0);

        let docs = res.docs as MonitorDocument[];

        let finalDocs = [];
        let all = offset ? [...results, ...docs] : [...docs];

        all.forEach((doc) => {
          if (
            !finalDocs
              .map((item) => item._source.pai_id)
              .includes(doc._source.pai_id)
          ) {
            finalDocs.push(doc);
          }
        });

        setResults(finalDocs);

        setNextOffset(res.next);
        setCount(res && res.total ? res.total || 0 : 0);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const queryAsString = () => {
    var str = [];
    for (var p in monitorQuery)
      if (monitorQuery.hasOwnProperty(p)) {
        if (p == "source") {
          if ((monitorQuery[p] || []).length > 0) {
            str.push(
              p +
                "=" +
                monitorQuery[p]
                  .map((item) => `${item.guid}_${item.name}`)
                  .join(",")
            );
          }
        } else if (p == "all" || p == "any" || p == "none") {
          if ((monitorQuery[p] || []).length > 0) {
            str.push(p + "=" + monitorQuery[p].join(","));
          }
        } else if (monitorQuery[p] && monitorQuery[p] != "") {
          str.push(p + "=" + monitorQuery[p]);
        }
      }
    return str.join("&");
  };

  const canLoadMore = () => {
    return (results || []).length < count;
  };

  const renderResults = () => {
    return (
      <div>
        {results.map((item, index) => (
          <DocumentItem
            key={index}
            doc={item}
            prospects={sortedProspects()}
            sources={sources}
          />
        ))}
        {canLoadMore() ? (
          <div
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <UnderlineButton
              onClick={() => {
                search(monitorQuery, nextOffset);
              }}
              text="load more"
            />
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: colors.lightBlue,
          zIndex: 100,
          marginTop: -5,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <ScoutTitle
            text="FILTER RESULTS:"
            withHelp={false}
            light={true}
            onClick={() => {}}
          />

          <input
            type="text"
            placeholder={"Search..."}
            style={{
              padding: 8,
              borderRadius: 4,
              display: "flex",
              flex: 1,
              border: "0px solid",
              color: colors.accent,
              backgroundColor: colors.white,
            }}
            value={monitorQuery.text}
            onChange={(evt) =>
              setMonitorQuery({ ...monitorQuery, text: evt.target.value })
            }
          />

          <div style={{ marginLeft: 12 }}>
            <Link to={`/monitor?${queryAsString()}`}>
              <CTAButton
                text={"SEARCH"}
                styles={{ backgroundColor: colors.accent, marginRight: "1rem" }}
                onClick={() => {
                  search(monitorQuery);
                }}
              />
            </Link>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        height: "calc(100% - 3.6rem)",
        position: "absolute",
        left: 0,
        top: "4rem",
        right: 0,
      }}
    >
      {renderHeader()}

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100vw",
          maxWidth: "100vw",
          height: "calc(100% - 3.6rem)",
        }}
      >
        {showAdvanced ? (
          <LeftFilters
            query={monitorQuery}
            setQuery={setMonitorQuery}
            sources={sources}
            clear={() => {}}
            enabled={sortedDiscoveries().length > 0}
          />
        ) : (
          <div />
        )}

        {sortedDiscoveries().length > 0 ? (
          <div
            style={{
              display: "flex",
              height: "100%",
              overflow: "scroll",
              backgroundColor: colors.white,
              flex: 1,
            }}
          >
            <div
              style={{
                padding: "1rem",
                marginBottom: 20,
                display: "flex",
                flexDirection: "column",
                flex: 1,
                overflowX: "hidden",
              }}
            >
              {loading ? (
                <div
                  style={{
                    position: "absolute",
                    left: "50vw",
                    top: "30vh",
                  }}
                >
                  <Spinner animation="border" />
                </div>
              ) : (
                <div />
              )}
              <div>
                {results.length > 0 ? (
                  renderResults()
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      flex: 1,
                    }}
                  >
                    <p
                      style={{
                        fontStyle: "italic",
                        opacity: 0.8,
                        marginTop: 100,
                        textAlign: "center",
                      }}
                    >
                      {loading ? "" : "No results."}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : stoplightId && !loading ? (
          <div
            style={{
              display: "flex",
              height: "100%",
              overflow: "scroll",
              backgroundColor: colors.white,
              flex: 1,
            }}
          >
            <div
              style={{
                padding: "1rem",
                marginBottom: 20,
                display: "flex",
                flexDirection: "column",
                flex: 1,
                overflowX: "hidden",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  flex: 1,
                }}
              >
                <p
                  style={{
                    fontStyle: "italic",
                    opacity: 0.8,
                    marginTop: 100,
                    textAlign: "center",
                  }}
                >
                  {
                    "Please create a discovery and wait 24 hours before searching."
                  }
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div
              style={{
                position: "absolute",
                left: "50vw",
                top: "30vh",
              }}
            >
              <Spinner animation="border" />
            </div>
          </div>
        )}
        <RightFilters sources={sources} stoplightId={stoplightId} />
      </div>
    </div>
  );
}

export default MonitorPage;
