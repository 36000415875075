import React from "react";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import PlayerAvatar from "./player-avatar";
import { Link, useHistory } from "react-router-dom";
import colors from "constants/colors";
import ScoutTitle from "./scout-title";
import CTALink from "./cta-link";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { PlayerService } from "services/PlayerService";

function SidebarPlayers(props: any) {
  let location = useLocation();
  const history = useHistory();
  const userId = useSelector((state: any) => state.firebase.auth.uid);
  const myProspects = useSelector((state: any) => state.firestore.data.myProspects);
  useFirestoreConnect([
    {
      collection: "users",
      doc: userId,
      subcollections: [
        { collection: "prospects", where: ["active", "==", true] },
      ],
      storeAs: "myProspects",
    },
  ]);

  const sortedProspects = () => {
    if (!isLoaded(myProspects)) {
      return [];
    }

    let prospects = myProspects
      ? Object.keys(myProspects).map((key) =>
          myProspects ? { ...myProspects[key], id: key } : null
        )
      : [];

    prospects.sort(function (a, b) {
      if (a.lastViewed && b.lastViewed) {
        if (a.lastViewed.seconds > b.lastViewed.seconds) return -1;
        if (a.lastViewed.seconds < b.lastViewed.seconds) return 1;
      } else if (a.lastViewed) {
        return -1;
      } else if (b.lastViewed) {
        return 1;
      }
      return 0;
    });

    return prospects
      .filter((item) => item !== null && item.active)
      .filter((item) => !location.pathname.includes(item.id))
      .slice(0, 6);
  };

  return props.hideProspects ? (
    <div />
  ) : (
    <div
      style={{
        backgroundColor: colors.white,
        display: "flex",
        flex: 1,
        flexDirection: "column",
        marginBottom: 20,
      }}
    >
      <Link to={"/prospects"}>
        <ScoutTitle text={"Recent Prospects"} withPlus={true} />
      </Link>

      <div
        style={{
          backgroundColor: colors.white,
          display: "flex",
          flex: 1,
          flexDirection: "column",

          marginTop: -15,
        }}
      >
        {(sortedProspects() || []).length > 0 ? (
          <div
            style={{
              flex: 1,
              backgroundColor: colors.white,
              padding: "1rem",
              paddingBottom: 0,
              borderRadius: 6,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {(sortedProspects() || []).map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    backgroundColor:
                      index % 2 === 1 ? colors.white : colors.lightGray,
                    alignItems: "center",
                    paddingLeft: 4,
                    paddingRight: 12,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: 6,
                      marginTop: 6,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <PlayerAvatar
                      player={item}
                      blueBackground={true}
                      containerStyles={{ marginRight: 5 }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Link
                        to={`/${item.college ? "collegeplayer" : "player"}/${
                          item.external_id
                        }`}
                        style={{}}
                      >
                        {PlayerService.getName(item)}
                        <span style={{ fontWeight: "bold" }}>
                          {item.academic_class || item.graduating_class
                            ? ` (${
                                item.college
                                  ? item.academic_class
                                  : item.graduating_class
                              })`
                            : ""}
                        </span>
                      </Link>
                      <p
                        style={{
                          color: colors.accent,
                          opacity: 0.7,
                          fontStyle: "italic",
                        }}
                      >
                        {item.college ? item.university : item.high_school}
                      </p>
                    </div>
                  </div>
                  <p style={{}}>
                    {item.college
                      ? item.position
                      : item.alt_position
                      ? `${item.primary_position}, ${item.alt_position}`
                      : item.primary_position}
                  </p>
                </div>
              );
            })}
            <CTALink
              styles={{ marginBottom: 12, marginTop: 12 }}
              linkTo={`/prospects`}
              text={"VIEW ALL"}
            />
          </div>
        ) : (
          <div
            style={{
              flex: 1,
              minHeight: 80,
              backgroundColor: colors.white,
              padding: "1rem",
              borderRadius: 6,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <p style={{ fontStyle: "italic", opacity: 0.8 }}>
              No prospects saved yet.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default SidebarPlayers;
