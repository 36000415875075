import React from "react";
import colors from "constants/colors";
import { AiFillCaretUp, AiFillCaretDown } from "react-icons/ai";

function ResultTableNCAA({ data, sortColumn, sortOrder, onSort }) {
  const headers = [
    { id: 1, name: "FirstName", key: "first_name" },
    { id: 2, name: "LastName", key: "last_name" },
    { id: 3, name: "Institution", key: "institution" },
    { id: 4, name: "Graduate", key: "is_graduate" },
    { id: 5, name: "MTR", key: "multiple_transfer_portal_records" },
    { id: 6, name: "Transfer Status", key: "transfer_status" },
    { id: 7, name: "Year", key: "year" },
    { id: 8, name: "Conference", key: "conference" },
    { id: 9, name: "Division", key: "division" },
  ];

  const renderArrow = (key) => {
    if (sortColumn === key && (sortOrder || sortOrder)) {
      return sortOrder === "asc" ? <AiFillCaretUp /> : <AiFillCaretDown />;
    }
    return null;
  };

  return (
    <div style={{ width: "125vw", overflowX: "auto" }}>
      {/* Header Row */}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(9, 1fr)",
          backgroundColor: colors.lightBlue,
          color: colors.white,
          padding: "10px 0",
          marginBottom: 10,
          alignItems: "center",
          fontWeight: "bold",
          width: "100%",
        }}
      >
        {headers.map((header) => (
          <div
            key={header.id}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              minWidth: 200,
            }}
            onClick={() => onSort(header.key)}
          >
            {header.name} {renderArrow(header.key)}
          </div>
        ))}
      </div>

      {/* Data Rows */}
      {data.map((player, index) => (
        <div
          key={index}
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(9, 1fr)",
            backgroundColor: index % 2 === 0 ? colors.lightGray : "white",
            padding: "10px 0",
            alignItems: "center",
            width: "100%",
            minHeight: 80,
          }}
        >
          {headers.map((header) => (
            <div
              key={header.id}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: 200,
              }}
            >
              <p>{player[header.key] || "N/A"}</p>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default ResultTableNCAA;
