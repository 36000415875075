/*****************************************************************************
 * Import
 *****************************************************************************/
import { useState } from "react";
// import { User } from "models/User";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";

/*****************************************************************************
 * Hooks
 *****************************************************************************/

export const useUserForId = (id: string): any => {
  useFirestoreConnect([
    {
      collection: "users",
      doc: id || undefined,
    },
  ]);
  
  const listData = useSelector<any, any>((state) => state.firestore.data.users);
  if (listData && Object.keys(listData).includes(id)) {
    return {
      ...listData[id],
      id: id,
    };
  }
  return null;
};

export const useMe = (): any => {
  const authData = useSelector<any, any>((state: any) => state.firebase.auth);
  const profileData = useSelector<any, any>((state: any) => state.firebase.profile);
  const me = {
    ...profileData,
    id: authData.uid,
    isEmpty: authData.isEmpty || profileData.isEmpty,
    isLoaded: authData.isLoaded && profileData.isLoaded,
  };
  return me;
};
