import React, { useState } from "react";
import { compose } from "recompose";
import {
  isLoaded,
  withFirestore,
  useFirestoreConnect,
} from "react-redux-firebase";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { PositionReport } from "models/PositionReport";
import moment from "moment";
import UnderlineButton from "shared/underline-button";
import colors from "constants/colors";
import ImagesList from "./components/images-list";
import VideosList from "./components/videos-list";
import PositionProReport from "./displays/position-pro-report";
import { Link } from "react-router-dom";
import GeneralReport from "./displays/general-report";
import NotesReport from "./displays/notes-report";
import PitcherReport from "./displays/pitcher-report";
import LoadingView from "shared/loading-view";
import { Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";

function DisplayReportNotification(props: any) {
  let { notificationId } = useParams();
  const userId = useSelector((state: any) => state.firebase.auth.uid);
  const currentNotification = useSelector(
    (state: any) => state.firestore.data.currentNotification
  ) as PositionReport;
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useFirestoreConnect([
    {
      collection: "notifications",
      doc: notificationId,
      storeAs: "currentNotification",
    },
  ]);

  if (!isLoaded(currentNotification)) {
    return <LoadingView />;
  }

  const currentReport = () => {
    if (currentNotification && currentNotification.report) {
      return currentNotification.report;
    }
    return null;
  };

  const renderPositionReport = () => {
    if (currentReport().kind === "PRO") {
      return <PositionProReport posReport={currentReport()} />;
    }
    return <div />;
  };

  const renderPitcherReport = () => {
    if (currentReport().kind === "PITCHER") {
      return <PitcherReport posReport={currentReport()} />;
    }
    return <div />;
  };

  const renderGeneralReport = () => {
    if (currentReport().kind === "GENERAL") {
      return <GeneralReport posReport={currentReport()} />;
    }
    return <div />;
  };

  const renderNotes = () => {
    if (currentReport().kind === "NOTES") {
      return <NotesReport posReport={currentReport()} />;
    }
    return <div />;
  };

  const saveReport = async () => {
    setLoading(true);
    const boardsDocRefConfig = {
      collection: "users",
      doc: userId,
      subcollections: [{ collection: "reports", doc: currentReport().id }],
    };

    await props.firestore.set(boardsDocRefConfig, {
      ...currentReport(),
      relatedNotificationId: notificationId,
      authorName: currentNotification.senderName,
    });

    setLoading(false);
    history.replace(`/display-report/${currentReport().id}`);
  };

  return currentReport() ? (
    <div
      style={{
        paddingLeft: "1rem",
        margin: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          marginTop: 20,
          marginBottom: 50,
          borderRadius: 8,
          backgroundColor: colors.white,
          display: "flex",
          flexDirection: "column",
          flex: 1,
          width: "60vw",

          padding: 20,
          color: colors.accent,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Link
            to={
              currentReport() && currentReport().playerId.includes("cl_")
                ? `/collegeplayer/${
                    currentReport() ? currentReport().playerId : ""
                  }`
                : `/player/${currentReport() ? currentReport().playerId : ""}`
            }
            style={{ color: colors.accent }}
          >
            <h2 style={{ fontFamily: "Raleway" }}>
              {currentReport() ? currentReport().playerName : ""}
            </h2>
          </Link>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div>
              {loading ? (
                <Spinner animation="border" />
              ) : (
                <UnderlineButton
                  text={"save to my reports"}
                  onClick={() => {
                    saveReport();
                  }}
                  color={colors.accent}
                />
              )}
            </div>
          </div>
        </div>

        <p>
          Report
          {currentReport().date
            ? moment(new Date(currentReport().date.seconds * 1000)).format(
                " MM/DD/YYYY"
              )
            : ""}
        </p>

        {renderPositionReport()}
        {renderPitcherReport()}
        {renderGeneralReport()}
        {renderNotes()}

        <div style={{}}>
          {currentReport().kind !== "NOTES" ? (
            <ImagesList posReport={currentReport()} large={false} />
          ) : (
            <div />
          )}
          {currentReport().kind !== "NOTES" ? (
            <VideosList posReport={currentReport()} />
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{
        paddingLeft: "1rem",
        margin: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <p>Sorry, we can't find that report.</p>
    </div>
  );
}

const enhance = compose(withFirestore);

export default enhance(DisplayReportNotification);
