import { useMe } from "../../hooks/users";
import colors from "constants/colors";
import SourceDropdown from "dropdowns/source-dropdown";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";
import { AiFillCaretDown, AiFillCaretRight } from "react-icons/ai";
import { Link, useHistory } from "react-router-dom";
import { MdChevronRight } from "react-icons/md";
import ReactChipInput from "./components/react-chip-input";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import { useFirestore, useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import moment from "moment";
import UnderlineButton from "shared/underline-button";
import AreYouSureModal from "modals/are-you-sure-modal";
import SaveMonitorFilterModal from "modals/save-monitor-filter-modal";

function LeftFilters({
  query,
  setQuery,
  clear,
  sources,
  enabled,
}: {
  query: any;
  setQuery: any;
  clear: any;
  sources: {
    active: boolean;

    guid: string;
    name: string;
  }[];
  enabled: boolean;
}) {
  const [visible, setVisible] = useState<string[]>([]);
  const [showSaved, setShowSaved] = useState(false);
  const me = useMe();
  const firestore = useFirestore();
  const history = useHistory();
  const myFilters = useSelector((state: any) => state.firestore.data.myFilters);
  const [deleteSavedSearch, setDeleteSavedSearch] = useState(null);
  const [showSaveSearchModal, setShowSaveSearchModal] = useState(false);

  useFirestoreConnect([
    {
      collection: "users",
      doc: me.id as unknown as string,
      subcollections: [{ collection: "monitorFilters" }],
      storeAs: `myFilters`,
    },
  ]);

  const filteredSavedSearches = () => {
    let saved = Object.keys(myFilters || {})
      .map((key) => (myFilters ? { ...myFilters[key], id: key } : null))
      .filter((item) => item !== null);

    return saved;
  };

  const renderTitle = (name) => {
    return (
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          color: colors.white,
        }}
      >
        <p className="position-title">{name}</p>
      </div>
    );
  };

  const queryAsString = () => {
    var str = [];
    for (var p in query)
      if (query.hasOwnProperty(p)) {
        if (p == "source") {
          if ((query[p] || []).length > 0) {
            str.push(
              p +
                "=" +
                query[p].map((item) => `${item.guid}_${item.name}`).join(",")
            );
          }
        } else if (p == "all" || p == "any" || p == "none") {
          if ((query[p] || []).length > 0) {
            str.push(p + "=" + query[p].join(","));
          }
        } else if (query[p] && query[p] != "") {
          str.push(p + "=" + query[p]);
        }
      }
    return str.join("&");
  };

  const renderSourceDropdown = () => {
    return sources.length > 0 ? (
      <div>
        <SourceDropdown
          sources={sources}
          selected={query.source}
          selectSource={(res) => {
            setQuery({ ...query, source: res });
          }}
        />
      </div>
    ) : (
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          color: colors.white,
        }}
      >
        <Spinner animation="border" />
      </div>
    );
  };

  const renderDaysBackSlider = () => {
    return (
      <div>
        <Slider
          min={0}
          max={356}
          step={1}
          onChange={(val) => setQuery({ ...query, daysBack: val })}
          defaultValue={query.daysBack}
          trackStyle={{}}
          railStyle={{}}
          dotStyle={{}}
          activeDotStyle={{}}
        />
      </div>
    );
  };

  const renderTextBox = (key: string) => {
    return (
      <div
        style={{
          backgroundColor: colors.white,
          borderRadius: 5,
          width: 270,
          maxWidth: 270,
          overflow: "hidden",
        }}
      >
        <ReactChipInput
          classes=""
          chips={query[key]}
          onSubmit={(chip) =>
            setQuery({ ...query, [key]: [...query[key], chip] })
          }
          onRemove={(index) => {
            let vals = [...(query[key] || [])];
            vals.splice(index, 1);
            setQuery({
              ...query,
              [key]: vals,
            });
          }}
          placeholder="Enter a search term..."
        />
      </div>
    );
  };

  const renderSearchItems = () => {
    return (
      <div>
        {renderTitle("Source:")}
        {renderSourceDropdown()}
        {renderTitle("All:")}
        {renderTextBox("all")}
        {renderTitle("Any:")}
        {renderTextBox("any")}
        {renderTitle("None:")}
        {renderTextBox("none")}
        {renderTitle(`Days Back: ${query.daysBack || 0}`)}
        {renderDaysBackSlider()}
      </div>
    );
  };

  const startSave = () => {
    setQuery(query);
    setShowSaveSearchModal(true);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "center",
        padding: 12,
        paddingTop: 40,
        minWidth: 320,
        backgroundColor: "#003366",
        height: "100%",
        overflow: "scroll",
      }}
    >
      <div className="monitor-advanced-container">
        <div
          style={{
            flexDirection: "column",
            display: "flex",
          }}
        >
          <h4
            style={{
              fontWeight: "bold",
              fontSize: 20,
              color: colors.white,
              textAlign: "center",
            }}
          >
            ADVANCED FILTERS
          </h4>
        </div>
      </div>

      <div>{renderSearchItems()}</div>

      <div
        style={{
          marginTop: 20,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {query && (query.source || []).length > 0 && enabled ? (
          <Link to={`/monitor?${queryAsString()}`}>
            <div className={"search-button"}>
              <p style={{ color: "#003366", fontSize: 20 }}>FILTER</p>
            </div>
          </Link>
        ) : (
          <div className={"search-button"} style={{ opacity: 0.5 }}>
            <p style={{ color: "#003366", fontSize: 20 }}>FILTER</p>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            width: "100%",
            marginTop: 4,
          }}
        >
          <Button
            onClick={() => {
              startSave();
            }}
            style={{
              color: "white",
              backgroundColor: "transparent",
              padding: 6,
              borderWidth: 0,
              boxShadow: "none",
            }}
          >
            <p
              style={{
                color: "#fff",
                textAlign: "center",
                // opacity: 0.6,
                textDecorationColor: "#fff",
                textDecorationLine: "underline",
                textDecorationStyle: "solid",
              }}
            >
              save search
            </p>
          </Button>

          <div
            style={{
              color: "white",
              backgroundColor: "transparent",
              padding: 6,
              borderWidth: 0,
              boxShadow: "none",
            }}
            onClick={() => {
              setQuery({
                text: "",
                all: [],
                any: [],
                none: [],

                source: [],
              });
              setVisible([]);
              history.push("/monitor");
              clear();
            }}
          >
            <p
              style={{
                color: "#fff",
                textAlign: "center",
                opacity: 0.6,
                textDecorationColor: "#fff",
                textDecorationLine: "underline",
                textDecorationStyle: "solid",
                cursor: "pointer",
              }}
            >
              clear results
            </p>
          </div>
        </div>
      </div>

      <div>
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            paddingLeft: 20,
            paddingRight: 20,
            marginTop: 20,
          }}
        >
          <h4
            style={{
              fontWeight: 300,
              fontSize: 20,
              color: colors.white,
              textAlign: "center",
            }}
          >
            MY SAVED FILTERS
          </h4>
          <Button
            variant="secondary"
            style={{
              marginTop: -12,
              color: "white",
              fontSize: 30,
              backgroundColor: "transparent",
              padding: 6,
              borderWidth: 0,
              boxShadow: "none",
            }}
            onClick={() => setShowSaved(!showSaved)}
          >
            {showSaved ? <AiFillCaretDown /> : <AiFillCaretRight />}
          </Button>
        </div>
        {showSaved ? (
          <div>
            {filteredSavedSearches().map((item, index) => {
              return item && item.id && item.query ? (
                <div
                  key={index}
                  style={{
                    padding: 8,
                    backgroundColor: colors.white,
                    color: colors.accent,
                    borderRadius: 5,
                    marginBottom: 5,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Link to={item.webUrl}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ fontWeight: "bold" }}>{item.name}</p>
                      <MdChevronRight
                        style={{ color: colors.accent, fontSize: 20 }}
                      />
                    </div>
                  </Link>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginLeft: -6,
                      marginTop: -3,
                    }}
                  >
                    <UnderlineButton
                      text="delete"
                      onClick={() => {
                        setDeleteSavedSearch(item);
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div />
              );
            })}
          </div>
        ) : (
          <div />
        )}
      </div>

      <SaveMonitorFilterModal
        onHide={() => setShowSaveSearchModal(false)}
        url={`/monitor?${queryAsString()}`}
        query={query}
        show={showSaveSearchModal}
      />
      <AreYouSureModal
        show={deleteSavedSearch != null}
        onHide={() => setDeleteSavedSearch(null)}
        title={"Delete Saved Filter?"}
        body={`Are you sure you want to delete your saved filter "${
          deleteSavedSearch ? deleteSavedSearch.name : ""
        }"?`}
        onConfirm={async () => {
          await firestore
            .collection("users")
            .doc(me.id as unknown as string)
            .collection("monitorFilters")
            .doc(deleteSavedSearch.id)
            .delete();
          setDeleteSavedSearch(null);
        }}
      />
    </div>
  );
}

export default LeftFilters;
