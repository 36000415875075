import { Text, View } from "@react-pdf/renderer";
import colors from "constants/colors";
import { Player } from "models/Player";
// import { User } from "models/User";
import React from "react";
import { PlayerService } from "services/PlayerService";

function PDFBreakdown({
  sortedProspects,
  me,
  year,
}: {
  sortedProspects: Player[];
  me: any;
  year: string,
}) {
  const getPlayer = (position: string, withAlt: boolean) => {
    if (position === "P") {
      return sortedProspects.filter(
        (item) =>
          item.primary_position === "LHP" ||
          item.primary_position === "RHP" ||
          item.primary_position === "Pitcher" ||
          item.position === "LHP" ||
          item.position === "RHP" ||
          item.position === "Pitcher"
      );
    }
    if (position === "IF") {
      return sortedProspects.filter(
        (item) =>
          item.primary_position === "1B" ||
          item.primary_position === "2B" ||
          item.primary_position === "3B" ||
          item.primary_position === "SS" ||
          item.primary_position === "MIF" ||
          item.primary_position === "Infield" ||
          item.primary_position === "First Base" ||
          item.position === "1B" ||
          item.position === "2B" ||
          item.position === "3B" ||
          item.position === "SS" ||
          item.position === "MIF" ||
          item.position === "Infield" ||
          item.position === "First Base"
      );
    }
    if (position === "OF") {
      return sortedProspects.filter(
        (item) =>
          item.primary_position === "OF" ||
          item.primary_position === "Outfield" ||
          item.position === "OF" ||
          item.position === "Outfield"
      );
    }
    if (position === "C") {
      return sortedProspects.filter(
        (item) =>
          item.primary_position === "C" ||
          item.primary_position === "Catcher" ||
          item.position === "C" ||
          item.position === "Catcher"
      );
    }
    let pros = sortedProspects.filter(
      (item) => item.primary_position === position || item.position === position
    );

    return pros;
  };

  const getPlayersWithScholarship = (
    position: string,
    kind: "both" | "schol" | "finAid"
  ) => {
    return (getPlayer(position, false) || [])
      .map((item) => ({
        ...item,
        totalVal: PlayerService.getScholarship(item, year, kind),
      }))
      .filter((item) => item.totalVal > 0);
  };

  const getScholarshipTotals = (
    position: string,
    kind: "both" | "schol" | "finAid"
  ) => {
    let pl = getPlayersWithScholarship(position, kind).map((item) => item.totalVal);
    let total = 0;
    pl.forEach((p) => {
      total = total + p;
    });
    return total;
  };

  const getAllScholarshipTotal = (kind: "both" | "schol" | "finAid") => {
    let pl = (sortedProspects || [] || [])
      .map((item) => PlayerService.getScholarship(item, year, kind, true))
      .filter((item) => item > 0);
    let total = 0.0;
    pl.forEach((p) => {
      total = total + p;
    });
    return total;
  };

  const th = (name) => {
    return (
      <View
        style={{
          backgroundColor: colors.lightBlue,
          border: "1px solid #467DB3",
          color: colors.white,
          textAlign: "center",
          padding: 4,
        }}
      >
        <Text style={{ fontSize: 12 }}>{name}</Text>
      </View>
    );
  };

  const td = (val: string, bold?: boolean) => {
    return (
      <View
        style={{
          border: "1px solid #467DB3",
          color: colors.accent,
          textAlign: "center",
          padding: 4,
        }}
      >
        <Text
          style={{
            fontSize: 12,
            textAlign: "center",
            fontFamily: bold ? "RalewayBold" : "Raleway",
          }}
        >
          {val}
        </Text>
      </View>
    );
  };

  const tdTwoVal = (val: string, valTwo: string, bold?: boolean) => {
    return (
      <View
        style={{
          border: "1px solid #467DB3",
          color: colors.accent,
          textAlign: "center",
          padding: 4,
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            fontFamily: bold ? "RalewayBold" : "Raleway",
          }}
        >
          <Text style={{ fontSize: 12, width: 60 }}>{val}</Text>
          <Text
            style={{
              fontSize: 12,
              width: 60,
              marginLeft: 20,
            }}
          >
            {valTwo}
          </Text>
        </View>
      </View>
    );
  };

  const renderOverviewTable = () => {
    let totalSch =
      getPlayersWithScholarship("P", "schol").length +
      getPlayersWithScholarship("OF", "schol").length +
      getPlayersWithScholarship("IF", "schol").length +
      getPlayersWithScholarship("C", "schol").length;

    let totalFin =
      getPlayersWithScholarship("P", "finAid").length +
      getPlayersWithScholarship("OF", "finAid").length +
      getPlayersWithScholarship("IF", "finAid").length +
      getPlayersWithScholarship("C", "finAid").length;

    return (
      <View
        style={{
          width: "100%",
          border: "1px solid #467DB3",
          backgroundColor: colors.white,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <View
          style={{
            width: "100%",
            border: "1px solid #467DB3",
            backgroundColor: colors.white,
            display: "flex",
            flexDirection: "column",
            flex: 0.5,
          }}
        >
          {th("Position")}
          {td("OF")}
          {td("IF")}
          {td("C")}
          {td("P")}
          {td("Total", true)}
        </View>

        <View
          style={{
            width: "100%",
            border: "1px solid #467DB3",
            backgroundColor: colors.white,
            display: "flex",
            flexDirection: "column",
            flex: 0.5,
          }}
        >
          {th("# Players")}
          {td(`${(getPlayer("OF", true) || []).length}`)}
          {td(`${(getPlayer("IF", true) || []).length}`)}
          {td(`${(getPlayer("C", true) || []).length}`)}
          {td(`${(getPlayer("P", true) || []).length}`)}
          {td(`${(sortedProspects || [] || []).length}`, true)}
        </View>

        <View
          style={{
            flex: 1,
            width: "100%",
            border: "1px solid #467DB3",
            backgroundColor: colors.white,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {th("# On Scholarship (+ FA)")}
          {tdTwoVal(
            `${getPlayersWithScholarship("OF", "schol").length}`,
            `(+${getPlayersWithScholarship("OF", "finAid").length})`
          )}
          {tdTwoVal(
            `${getPlayersWithScholarship("IF", "schol").length}`,
            `(+${getPlayersWithScholarship("IF", "finAid").length})`
          )}
          {tdTwoVal(
            `${getPlayersWithScholarship("C", "schol").length}`,
            `(+${getPlayersWithScholarship("C", "finAid").length})`
          )}
          {tdTwoVal(
            `${getPlayersWithScholarship("P", "schol").length}`,
            `(+${getPlayersWithScholarship("P", "finAid").length})`
          )}
          {tdTwoVal(`${totalSch}`, `(+${totalFin})`, true)}
        </View>

        <View
          style={{
            flex: 1,
            width: "100%",
            border: "1px solid #467DB3",
            backgroundColor: colors.white,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {th(`Total Amount`)}
          {tdTwoVal(
            `${getScholarshipTotals("OF", "schol").toFixed(2)}`,
            `(+${getScholarshipTotals("OF", "finAid").toFixed(2)})`
          )}
          {tdTwoVal(
            `${getScholarshipTotals("IF", "schol").toFixed(2)}`,
            `(+${getScholarshipTotals("IF", "finAid").toFixed(2)})`
          )}
          {tdTwoVal(
            `${getScholarshipTotals("C", "schol").toFixed(2)}`,
            `(+${getScholarshipTotals("C", "finAid").toFixed(2)})`
          )}
          {tdTwoVal(
            `${getScholarshipTotals("P", "schol").toFixed(2)}`,
            `(+${getScholarshipTotals("P", "finAid").toFixed(2)})`
          )}
          {tdTwoVal(
            `${getAllScholarshipTotal("schol").toFixed(2)}`,
            `(+${getAllScholarshipTotal("finAid").toFixed(2)})`,
            true
          )}
        </View>
      </View>
    );
  };

  const renderTable = (kind: "schol" | "finAid") => {
    let totalLimit = 0;
    let peopleLimit = me.scholarshipPlayersLimit || 0;
    if (kind === "schol") {
      totalLimit = me.totalScholarshipVal ? me.totalScholarshipVal : 11.7;
    }
    if (kind === "finAid") {
      totalLimit = me.totalFinAidVal ? me.totalFinAidVal : 0;
      peopleLimit = 0;
    }

    let total =
      getPlayersWithScholarship("P", kind).length +
      getPlayersWithScholarship("OF", kind).length +
      getPlayersWithScholarship("IF", kind).length +
      getPlayersWithScholarship("C", kind).length;

    const tooManyPlayers = peopleLimit !== 0 && total > peopleLimit;

    let totalString = `${total}`;

    return (
      <View
        style={{
          width: "100%",
          border: "1px solid #467DB3",
          backgroundColor: colors.white,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <View
          style={{
            width: "100%",
            border: "1px solid #467DB3",
            backgroundColor: colors.white,
            display: "flex",
            flexDirection: "column",
            flex: 0.5,
          }}
        >
          {th("Position")}
          {td("OF")}
          {td("IF")}
          {td("C")}
          {td("P")}
          {td("Total", true)}
        </View>
        <View
          style={{
            width: "100%",
            border: "1px solid #467DB3",
            backgroundColor: colors.white,
            display: "flex",
            flexDirection: "column",
            flex: 0.5,
          }}
        >
          {th("# Players")}
          {td(`${(getPlayer("OF", true) || []).length}`)}
          {td(`${(getPlayer("IF", true) || []).length}`)}
          {td(`${(getPlayer("C", true) || []).length}`)}
          {td(`${(getPlayer("P", true) || []).length}`)}
          {td(`${(sortedProspects || [] || []).length}`, true)}
        </View>

        <View
          style={{
            width: "100%",
            border: "1px solid #467DB3",
            backgroundColor: colors.white,
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          {th(kind === "schol" ? "# On Scholarship" : "# On Financial Aid")}
          {td(`${getPlayersWithScholarship("OF", kind).length}`)}

          {td(`${getPlayersWithScholarship("IF", kind).length}`)}
          {td(`${getPlayersWithScholarship("C", kind).length}`)}
          {td(`${getPlayersWithScholarship("P", kind).length}`)}
          {td(totalString, true)}
        </View>
        <View
          style={{
            width: "100%",
            border: "1px solid #467DB3",
            backgroundColor: colors.white,
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          {th(
            `${
              kind === "schol"
                ? "Scholarship "
                : kind === "finAid"
                ? "Financial Aid "
                : ""
            }Total Amount`
          )}

          {td(`${getScholarshipTotals("OF", kind).toFixed(2)}`)}
          {td(`${getScholarshipTotals("IF", kind).toFixed(2)}`)}
          {td(`${getScholarshipTotals("C", kind).toFixed(2)}`)}
          {td(`${getScholarshipTotals("P", kind).toFixed(2)}`)}
          {td(`${getAllScholarshipTotal(kind).toFixed(2)}`, true)}
        </View>
      </View>
    );
  };

  return (
    <View style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
      <View style={{ backgroundColor: colors.accent }}>
        <Text
          style={{ fontSize: 14, color: "#fff", padding: 4, paddingLeft: 8 }}
        >
          {"Athletic Scholarship"}
        </Text>
      </View>
      {renderTable("schol")}
      <View style={{ backgroundColor: colors.accent, marginTop: 20 }}>
        <Text
          style={{ fontSize: 14, color: "#fff", padding: 4, paddingLeft: 8 }}
        >
          {"Financial Aid"}
        </Text>
      </View>
      {renderTable("finAid")}
      <View style={{ backgroundColor: colors.accent, marginTop: 20 }}>
        <Text
          style={{ fontSize: 14, color: "#fff", padding: 4, paddingLeft: 8 }}
        >
          {"Overview"}
        </Text>
      </View>

      {renderOverviewTable()}
    </View>
  );
}

export default PDFBreakdown;
