import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./reports.css";
import { QueryHelper } from "services/QueryHelper";
import { Player } from "models/Player";
import ReportKindDropDown from "../../dropdowns/report-kind-dropdown";
import { PlayerService } from "services/PlayerService";
import UnderlineButton from "shared/underline-button";
import ProspectDropdown from "dropdowns/prospect-dropdown";
import CTAButton from "shared/cta-button";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { compose } from "recompose";
import { Spinner } from "react-bootstrap";
import {
  isLoaded,
  withFirestore,
  useFirestoreConnect,
} from "react-redux-firebase";
import {
  EmptyGeneralReport,
  EmptyNotesReport,
  EmptyPitcherReport,
  EmptyPositionReport,
} from "models/PositionReport";

function CreateReport(props: any) {
  let { playerId } = useParams();
  const userId = useSelector((state: any) => state.firebase.auth.uid);
  const [prospect, setProspect] = useState<Player | null>(null);
  const [reportKind, setReportKind] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const myProspects = useSelector((state: any) => state.firestore.data.myProspects);
  useFirestoreConnect([
    {
      collection: "users",
      doc: userId,
      subcollections: [
        { collection: "prospects", where: ["active", "==", true] },
      ],
      storeAs: "myProspects",
    },
  ]);

  useEffect(() => {
    fetchDataForURLParams();
  }, []);

  const sortedProspects = () => {
    let prospects = myProspects
      ? Object.keys(myProspects).map((key) =>
          myProspects ? { ...myProspects[key], id: key } : null
        )
      : [];

    return prospects.filter((item) => item !== null && item.active);
  };

  const fetchDataForURLParams = async () => {
    if (playerId && playerId !== "any") {
      let p = await QueryHelper.getPlayerForExternalId(playerId);
      if (p && p.data && p.data.length > 0) {
        setProspect(p.data[0]);
        setTimeout((_) => {
          startDraft();
        }, 1000);
      }
    }
  };

  const startDraft = async () => {
    if (prospect && reportKind !== "") {
      setLoading(true);

      let report = EmptyPositionReport;
      if (reportKind === "Pitcher 20-80 Report") {
        report = EmptyPitcherReport;
      } else if (reportKind === "General Report") {
        //@ts-ignore
        report = EmptyGeneralReport;
      } else if (reportKind === "Notes") {
        //@ts-ignore
        report = EmptyNotesReport;
      }

      report.playerId = prospect.external_id;
      report.playerName = PlayerService.getName(prospect);

      report.oFP = {
        p: "0",
        f: "0",
        adj: "0",
      };

      report.date = new Date();
      report.lastUpdated = new Date();

      const boardsDocRefConfig = {
        collection: "users",
        doc: userId,
        subcollections: [{ collection: "reports" }],
      };

      let r = await props.firestore.add(boardsDocRefConfig, report);
      setLoading(false);
      history.replace(`/edit-report/${r.id}`);
    }
  };

  return (
    <div
      style={{
        paddingLeft: "1rem",
        margin: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h2 style={{ fontFamily: "Raleway" }}>Create Report</h2>
      <div style={{ marginTop: 12 }}>
        {prospect ? (
          <div style={{ flexDirection: "row", display: "flex" }}>
            <p style={{ marginRight: 8, paddingTop: 6 }}>
              {PlayerService.getName(prospect)}
            </p>
            {sortedProspects().length > 0 ? (
              <UnderlineButton
                onClick={() => setProspect(null)}
                text={"edit"}
              />
            ) : (
              <div />
            )}
          </div>
        ) : (
          <ProspectDropdown
            selectProspect={(p) => {
              setProspect(p);
            }}
          />
        )}
      </div>
      <div style={{ marginTop: 12 }}>
        {reportKind === "" ? (
          <ReportKindDropDown selectReportKind={(ki) => setReportKind(ki[0])} />
        ) : (
          <div style={{ flexDirection: "row", display: "flex" }}>
            <p style={{ marginRight: 8, paddingTop: 6 }}>{reportKind}</p>
            <UnderlineButton onClick={() => setReportKind("")} text={"edit"} />
          </div>
        )}
      </div>

      {loading ? (
        <Spinner animation="border" style={{ marginTop: 20 }} />
      ) : (
        <CTAButton
          text={"START"}
          onClick={startDraft}
          styles={{ marginTop: 20, paddingTop: 6, paddingBottom: 6 }}
        />
      )}
    </div>
  );
}

const enhance = compose(withFirestore);

export default enhance(CreateReport);
