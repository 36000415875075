/*****************************************************************************
 * Import
 *****************************************************************************/
// import { User } from "models/User";
import { useSelector } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import { toArray } from "services/utils";

/*****************************************************************************
 * Hooks
 *****************************************************************************/

export const useSharedScholarshipsForUser = (user: any) => {
  const key = "sharedScholarships";
  
  useFirestoreConnect([{
      collection: "users",
      doc: user.id,
      subcollections: [
        {
          collection: "sharedScholarships",
        },
      ],
      storeAs: "sharedScholarships",
  }]);

  const defaultBoard = {
    senderId: user.id,
    name: `${user.firstName} ${user.lastName}`,
  }
  
  return useSelector<any, any>((state) =>
    (Object.keys(state.firestore.data).includes(key) &&
     toArray(state.firestore.data[key] || {}).length > 0)
    ? [ defaultBoard, ...toArray(state.firestore.data[key] || {}) ]
    : [defaultBoard]);
}
