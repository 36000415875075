import colors from "constants/colors";
import React, { useEffect, useState } from "react";
import { useFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import LabelledDatePicker from "pages/reports-create-page/components/labelled-date-picker";
import { Player } from "models/Player";
import { PlayerService } from "services/PlayerService";
import AddToCalendarHOC from "react-add-to-calendar-hoc";
import moment from "moment";

function NotifyMeModal(props: {
  show: boolean;
  onHide: any;
  eventDate?: Date;
  editing?: any;
  currentPlayer: Player;
}) {
  const userId = useSelector((state: any) => state.firebase.auth.uid);
  const [date, setDate] = useState(null);
  const [note, setNote] = useState("");
  const firestore = useFirestore();

  const ATCDropdown = (args) => (
    <div className="atc-dropdown">
      {args.children.map((link, i) => (
        <p style={{ marginTop: 12 }} key={i}>
          {link}
        </p>
      ))}
    </div>
  );

  const ATCWrapper = (args) => (
    <a onClick={args.onClick} className="atc-item" href="/">
      {args.children}
    </a>
  );

  const AddToCalendarDropdown = AddToCalendarHOC(ATCWrapper, ATCDropdown);

  useEffect(() => {
    if (props.editing) {
      if (props.editing.eventDate) {
        setDate(new Date(props.editing.eventDate.seconds * 1000));
      }
    }
  }, []);

  useEffect(() => {
    if (props.eventDate && date === null) {
      setDate(props.eventDate);
    }
  });

  const save = () => {
    let ev = {
      eventDate: date,
      player: props.currentPlayer,
      note: note,
    };

    if (props.editing) {
      let docRefConfig = {
        collection: "users",
        doc: userId,
        subcollections: [{ collection: "reminders", doc: props.editing.id }],
      };
      firestore.update(docRefConfig, ev);

      setNote("");

      toast.dark(`Reminder updated!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } else {
      let docRefConfig = {
        collection: "users",
        doc: userId,
        subcollections: [{ collection: "reminders" }],
      };
      firestore.add(docRefConfig, ev);

      toast.dark(`Reminder created!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }

    props.onHide();
  };

  const calEvent = () => {
    const startDatetime = moment(date).utc();
    const endDatetime = startDatetime.clone().add(10, "minutes");
    const duration = moment.duration(endDatetime.diff(startDatetime)).asHours();
    const event = {
      description: note,
      duration,
      endDatetime: endDatetime.format("YYYYMMDDTHHmmssZ"),
      startDatetime: startDatetime.format("YYYYMMDDTHHmmssZ"),
      title: `Reminder: ${PlayerService.getName(props.currentPlayer)}`,
    };
    return event;
  };

  return (
    <Modal
      {...props}
      //@ts-ignore
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          borderBottomWidth: 0,
          flexDirection: "column",
          display: "flex",
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">Remind Me</Modal.Title>
        <p>{PlayerService.getName(props.currentPlayer)}</p>
      </Modal.Header>
      <Modal.Body
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          display: "flex",
          flexDirection: "column",
        }}
        className="searching"
      >
        <LabelledDatePicker
          showTime={true}
          date={date}
          setDate={(date) => {
            setDate(date);
          }}
          containerStyles={{ flex: 1 }}
        />

        <textarea
          style={{
            minHeight: 180,
            height: 180,
            marginTop: 20,
            marginBottom: 10,
            display: "flex",
            flex: 1,
            borderRadius: 5,
            color: "#fff",
            backgroundColor: "transparent",
            border: "2px solid rgba(256, 256, 256, 0.4)",
          }}
          value={note}
          placeholder="Your note..."
          onChange={(evt) => setNote(evt.target.value)}
        />

        <div style={{ display: "flex", flexDirection: "row", marginTop: 20 }}>
          <AddToCalendarDropdown
            linkProps={{
              className: "atc-dropdown-title",
            }}
            event={calEvent()}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={props.onHide}
          style={{
            borderColor: colors.accent,
            backgroundColor: colors.white,
            color: colors.accent,
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            save();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NotifyMeModal;
