import colors from "constants/colors";
import React, { useEffect, useState } from "react";
import { compose } from "recompose";
import { useFirestore, withFirestore } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import TeamDropdown from "dropdowns/team-dropdown";
import { Player } from "models/Player";
import { CollegePlayer } from "models/CollegePlayer";
import SmallButton from "pages/search-page/components/small-button";
import { useMe } from "../hooks/users";
// import { User } from "models/User";
import YearDropdown from "dropdowns/year-dropdown";
import Switch from "react-switch";
import UnderlineButton from "shared/underline-button";
import { Row } from "components/basic";
import { PlayerService } from "services/PlayerService";

export default function BigBoardPlayerPositionModal({
  onHide,
  show,
  player,
  boardId,
}: {
  player: Player | CollegePlayer
  boardId: string;
  onHide: any;
  show: boolean;
}) {
  const firestore = useFirestore();
  const [bbPositions, setBbPositions] = useState([]);
  const pos = PlayerService.getPosition(player);
  const positions = pos.split(", ");

  const battery = [ "RHP", "LHP" ];
  const infield = [ "1B", "2B", "3B", "SS", "MIF" ];
  const positionMap = {
    "IF": [ ...infield ],
    "UT": [ ...infield, "OF" ],
    "P": [ ...battery ],
  }

  const positionOptions = positions.reduce((accum, curr) => {
    return [ ...accum, ...(positionMap[curr] || [curr]) ]
  }, []);

  const handleHide = () => {
    setBbPositions([])
    onHide();
  }

  const save = async () => {
    await firestore
      .collection("boards")
      .doc(boardId)
      .collection("prospects")
      .doc(player.external_id)
      .set({
        ...player,
        bbPositions: bbPositions,
      });

    handleHide();
  }

  const handleSelectPosition = (pos: string) => {
    if (bbPositions.includes(pos)) {
      setBbPositions(bbPositions.filter(item => item !== pos));
    } else {
      setBbPositions([ ...bbPositions, pos ])
    }
  }
  
  return (
    <Modal
      show={show}
      //@ts-ignore
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleHide}
    >
      <Modal.Header
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          borderBottomWidth: 0,
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          Select Position ({player?.position})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          display: "flex",
          flexDirection: "column",
          paddingTop: 0,
          marginTop: 0,
        }}
        className="searching"
      >
        <Row style={{ flexWrap: "wrap", gap: "8px" }}>
          {positionOptions.map(pos => (
            <SmallButton
              title={pos}
              selected={bbPositions.includes(pos)}
              onClick={() => handleSelectPosition(pos)}
              style={{ margin: 0 }}
            />
          ))}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={handleHide}
          style={{
            borderColor: colors.accent,
            backgroundColor: colors.white,
            color: colors.accent,
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={!bbPositions.length}
          onClick={save}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
