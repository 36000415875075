import { combineReducers } from "redux";
import { firebaseReducer as firebase } from "react-redux-firebase";
import { persistReducer } from "redux-persist";
import localStorage from "redux-persist/lib/storage";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import { connectRouter } from "connected-react-router";
import { firestoreReducer } from "redux-firestore";

export default function makeRootReducer(history) {
  return combineReducers({
    firestore: firestoreReducer,
    firebase: persistReducer(
      { key: "firebaseState", storage: localStorage, stateReconciler: hardSet },
      firebase
    ),
    router: connectRouter(history),
  });
}
