/* export const firebaseConfig = {
 *   apiKey: "AIzaSyDjpotiR3Smhh0erjAJk3SatAhiy8ol76A",
 *   authDomain: "scout2-9bc3f.firebaseapp.com",
 *   databaseURL: "https://scout2-9bc3f.firebaseio.com",
 *   projectId: "scout2-9bc3f",
 *   storageBucket: "scout2-9bc3f.appspot.com",
 *   messagingSenderId: "125946110793",
 *   appId: "1:125946110793:web:465e90edc5233235aab098",
 *   measurementId: "G-PBFNQ12G64",
 * };
 *  */
export const firebaseConfig = {
  apiKey: "AIzaSyDjpotiR3Smhh0erjAJk3SatAhiy8ol76A",
  authDomain: "scout2-9bc3f.firebaseapp.com",
  databaseURL: "https://scout2-9bc3f.firebaseio.com",
  projectId: "scout2-9bc3f",
  storageBucket: "scout2-9bc3f.appspot.com",
  messagingSenderId: "125946110793",
  appId: "1:125946110793:web:465e90edc5233235aab098",
  measurementId: "G-PBFNQ12G64"
};