import colors from "constants/colors";
import AreYouSureModal from "modals/are-you-sure-modal";
import ShareReportModal from "modals/share-report-modal";
import { useHistory } from "react-router-dom";
import { PositionReport } from "models/PositionReport";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import { Link } from "react-router-dom";
import LargePlayerImage from "shared/large-player-image";
import StarButton from "shared/star-button";
import UnderlineButton from "shared/underline-button";
import XLSX from "xlsx";
import { toast } from "react-toastify";

function ReportCard(props: {
  report: PositionReport;
  small?: boolean;
  index?: number;
}) {
  const [modalShow, setModalShow] = useState(false);
  const [copyModalShow, setCopyModalShow] = useState(false);
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const history = useHistory();

  const userId = useSelector((state: any) => state.firebase.auth.uid);
  const prospect = useSelector((state: any) =>
    state.firestore.data.myProspects &&
    Object.keys(state.firestore.data.myProspects).includes(
      props.report.playerId
    )
      ? state.firestore.data.myProspects[props.report.playerId]
      : null
  );

  const firestore = useFirestore();

  const copyReport = async () => {
    let newReport = { ...props.report };
    newReport.date = new Date();
    delete newReport.relatedNotificationId;
    delete newReport.authorName;

    const boardsDocRefConfig = {
      collection: "users",
      doc: userId,
      subcollections: [{ collection: "reports" }],
    };

    let r = await firestore.add(boardsDocRefConfig, newReport);
    history.replace(`/edit-report/${r.id}`);
  };

  const deleteReport = async () => {
    setDeleteModalShow(false);
    const boardsDocRefConfig = {
      collection: "users",
      doc: userId,
      subcollections: [{ collection: "reports", doc: props.report.id }],
    };
    await firestore.delete(boardsDocRefConfig);
    toast.dark(`Report deleted.`, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  return (
    <div key={props.index ? props.index : props.report.id}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginRight: 20,
          backgroundColor: props.small ? colors.accent : colors.white,
          borderColor: colors.accent,

          width: props.small ? 300 : 350,

          borderRadius: 8,
          marginBottom: 20,
          padding: props.small ? 12 : 20,
          paddingBottom: props.small ? 12 : 5,
        }}
      >
        <Link
          to={`/display-report/${props.report.id}`}
          style={{ textDecoration: "none" }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            {props.small ? (
              <div />
            ) : (
              <LargePlayerImage
                containerStyles={{}}
                player={prospect}
                playerName={props.report.playerName}
              />
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: props.small ? 0 : 14,
              }}
            >
              <h4
                style={{
                  fontWeight: "bold",
                  color: props.small ? colors.white : colors.accent,
                }}
              >
                {props.report.playerName}
              </h4>

              <p
                style={{
                  color: props.small ? colors.white : colors.accent,
                }}
              >
                {props.report.date && props.report.date.seconds
                  ? moment(new Date(props.report.date.seconds * 1000)).format(
                      "MM/DD/YYYY"
                    )
                  : "N/A"}
                <span style={{ marginLeft: 4, fontWeight: 800 }}>
                  {props.report.kind === "GENERAL"
                    ? "(GEN)"
                    : props.report.kind === "NOTES"
                    ? "(notes)"
                    : props.report.kind === "PITCHER"
                    ? "(PRO)"
                    : "(PRO)"}
                </span>
              </p>

              <div style={{ flex: 1 }} />
              {props.report.relatedNotificationId && props.report.authorName ? (
                <p
                  style={{
                    color: props.small ? colors.white : colors.lightBlue,
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >{`Shared by ${props.report.authorName}`}</p>
              ) : (
                <div />
              )}
            </div>
          </div>
        </Link>

        <div style={{ flex: 1 }} />

        <div style={{ flexDirection: "row", display: "flex" }}>
          <StarButton
            playerId={props.report.playerId}
            size={24}
            buttonStyles={{}}
          />
          {props.report.relatedNotificationId && props.report.authorName ? (
            <UnderlineButton
              text={"copy"}
              onClick={() => {
                setCopyModalShow(true);
              }}
              color={props.small ? colors.white : colors.accent}
            />
          ) : (
            <Link to={`/edit-report/${props.report.id}`}>
              <UnderlineButton
                text={"edit"}
                onClick={() => {}}
                color={props.small ? colors.white : colors.accent}
              />
            </Link>
          )}

          <UnderlineButton
            text={"delete"}
            onClick={() => {
              setDeleteModalShow(true);
            }}
            color={props.small ? colors.white : colors.accent}
          />
          <UnderlineButton
            text={"share"}
            color={props.small ? colors.white : colors.accent}
            onClick={() => {
              setModalShow(true);
            }}
          />
          <Link to={`/display-pdf/${props.report.id}`}>
            <UnderlineButton
              text={"report.pdf"}
              onClick={() => {}}
              color={props.small ? colors.white : colors.accent}
            />
          </Link>
          {/* {canExportXLS() ? (
            <div style={{}}>
              <UnderlineButton
                text="report.xls"
                onClick={() => exportxls()}
                color={colors.accent}
              />
            </div>
          ) : (
            <div />
          )} */}
        </div>
      </div>

      <ShareReportModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        report={props.report}
      />
      <AreYouSureModal
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)}
        onConfirm={deleteReport}
        title={"Delete Report"}
        body={"Are you sure you want to delete this report?"}
      />
      <AreYouSureModal
        show={copyModalShow}
        onHide={() => setCopyModalShow(false)}
        onConfirm={copyReport}
        title={"Copy Report"}
        body={"Do you want to make an editable copy of this shared report?"}
      />
    </div>
  );
}

export default ReportCard;
