import { GameEvent } from "./GameEvent";

export interface PositionReport {
  senderName: any;
  report: PositionReport;
  lastUpdated?: any;
  id?: string;
  draft?: boolean;
  shareURL?: string;
  pdfURL?: string;
  photos?: [];
  authorName?: string;
  authorId?: string;
  relatedNotificationId?: string;

  relatedGames?: GameEvent[];

  kind: "PRO" | "PITCHER" | "GENERAL" | "NOTES";
  playerName: string;
  playerId: string;
  date: any;
  roleFuturePosition: string;
  round: string;
  games: string;
  innings: string;
  aBs: string;
  raw: string;
  adj: string;
  current: string;
  future: string;
  physicalDescription: string;
  playerComparison: string;

  hittingAbility: {
    p: string;
    f: string;
    adj: string;
    comments: string;
  };
  hitAppType: {
    p: string;
    f: string;
    adj: string;
    comments: string;
  };
  powerFreq: {
    p: string;
    f: string;
    adj: string;
    comments: string;
  };
  rawPower: {
    p: string;
    f: string;
    adj: string;
    comments: string;
  };
  runningAbility: {
    p: string;
    f: string;
    adj: string;
    comments: string;
  };
  baseStealer: {
    p: string;
    f: string;
    adj: string;
    comments: string;
  };
  armStrength: {
    p: string;
    f: string;
    adj: string;
    comments: string;
  };
  fieldingAbility: {
    p: string;
    f: string;
    adj: string;
    comments: string;
  };
  hands: {
    p: string;
    f: string;
    adj: string;
    comments: string;
  };
  range: {
    p: string;
    f: string;
    adj: string;
    comments: string;
  };
  feet: {
    p: string;
    f: string;
    adj: string;
    comments: string;
  };
  canHePlay: {
    p: string;
    f: string;
    adj: string;
    comments: string;
  };
  oFP?: {
    p: any;
    f: any;
    adj: any;
  };
  aggressiveness: string;
  athleticism: string;
  bodyControl: string;
  instincts: string;
  competitiveness: string;
  baseballIQ: string;
  handEyeControl: string;
  bp: string;
  infield: string;
  oFPOffense: string;
  oFPDefense: string;
  homeToFirst: string;
  sixtyYard: string;
  fortyYard: string;
  posInOrder: string;
  character: string;
  entryLevel: string;
  eTA: string;
  armAngle?: string;
  armAction?: {
    value: string;
    comments: string;
  };
  delivery?: {
    value: string;
    comments: string;
  };
  howWellKnown: {
    value: string;
    comments: string;
  };
  howWellSeen: {
    value: string;
    comments: string;
  };
  signability: {
    value: string;
    comments: string;
  };
  summary: string;
  videos: string[];

  aptitude?: string;
  poise?: string;
  fielding?: string;
  deception?: string;
  releaseTimes?: string;
  outpitch?: string;

  fB?: {
    p: string;
    f: string;
    adj: string;
    h: string;
    l: string;
    a: string;
  };
  fBMVMT?: {
    p: string;
    f: string;
    adj: string;
    h: string;
    l: string;
    a: string;
  };
  fBStretch?: {
    p: string;
    f: string;
    adj: string;
    h: string;
    l: string;
    a: string;
  };
  sL?: {
    p: string;
    f: string;
    adj: string;
    h: string;
    l: string;
    a: string;
  };
  cB?: {
    p: string;
    f: string;
    adj: string;
    h: string;
    l: string;
    a: string;
  };
  cH?: {
    p: string;
    f: string;
    adj: string;
    h: string;
    l: string;
    a: string;
  };
  control?: {
    p: string;
    f: string;
    adj: string;
    h: string;
    l: string;
    a: string;
  };
  command?: {
    p: string;
    f: string;
    adj: string;
    h: string;
    l: string;
    a: string;
  };
  canHePitch?: {
    p: string;
    f: string;
    adj: string;
    h: string;
    l: string;
    a: string;
  };
}

export const EmptyPositionReport: PositionReport = {
  kind: "PRO",
  playerName: "",
  playerId: "",
  date: null,
  relatedGames: [],
  roleFuturePosition: "",
  round: "",
  games: "",
  innings: "",
  aBs: "",
  raw: "",
  adj: "",
  current: "",
  future: "",
  physicalDescription: "",
  playerComparison: "",
  summary: "",
  hittingAbility: {
    p: "",
    f: "",
    adj: "",
    comments: "",
  },
  hitAppType: {
    p: "",
    f: "",
    adj: "",
    comments: "",
  },
  powerFreq: {
    p: "",
    f: "",
    adj: "",
    comments: "",
  },
  rawPower: {
    p: "",
    f: "",
    adj: "",
    comments: "",
  },
  runningAbility: {
    p: "",
    f: "",
    adj: "",
    comments: "",
  },
  baseStealer: {
    p: "",
    f: "",
    adj: "",
    comments: "",
  },
  armStrength: {
    p: "",
    f: "",
    adj: "",
    comments: "",
  },
  fieldingAbility: {
    p: "",
    f: "",
    adj: "",
    comments: "",
  },
  hands: {
    p: "",
    f: "",
    adj: "",
    comments: "",
  },
  range: {
    p: "",
    f: "",
    adj: "",
    comments: "",
  },
  feet: {
    p: "",
    f: "",
    adj: "",
    comments: "",
  },
  canHePlay: {
    p: "",
    f: "",
    adj: "",
    comments: "",
  },
  oFP: {
    p: 0,
    f: 0,
    adj: 0,
  },
  aggressiveness: "",
  athleticism: "",
  bodyControl: "",
  instincts: "",
  competitiveness: "",
  baseballIQ: "",
  handEyeControl: "",
  bp: "",
  infield: "",
  oFPOffense: "",
  oFPDefense: "",
  homeToFirst: "",
  sixtyYard: "",
  fortyYard: "",
  posInOrder: "",
  character: "",
  entryLevel: "",
  eTA: "",
  howWellKnown: {
    value: "",
    comments: "",
  },
  howWellSeen: {
    value: "",
    comments: "",
  },
  signability: {
    value: "",
    comments: "",
  },

  videos: [],
  senderName: undefined,
  report: undefined
};

export const EmptyPitcherReport: PositionReport = {
  kind: "PITCHER",
  playerName: "",
  playerId: "",
  date: null,
  relatedGames: [],
  roleFuturePosition: "",
  round: "",
  games: "",
  innings: "",
  aBs: "",
  raw: "",
  adj: "",
  current: "",
  future: "",
  physicalDescription: "",
  playerComparison: "",
  summary: "",
  hittingAbility: {
    p: "",
    f: "",
    adj: "",
    comments: "",
  },
  hitAppType: {
    p: "",
    f: "",
    adj: "",
    comments: "",
  },
  powerFreq: {
    p: "",
    f: "",
    adj: "",
    comments: "",
  },
  rawPower: {
    p: "",
    f: "",
    adj: "",
    comments: "",
  },
  runningAbility: {
    p: "",
    f: "",
    adj: "",
    comments: "",
  },
  baseStealer: {
    p: "",
    f: "",
    adj: "",
    comments: "",
  },
  armStrength: {
    p: "",
    f: "",
    adj: "",
    comments: "",
  },
  fieldingAbility: {
    p: "",
    f: "",
    adj: "",
    comments: "",
  },
  hands: {
    p: "",
    f: "",
    adj: "",
    comments: "",
  },
  range: {
    p: "",
    f: "",
    adj: "",
    comments: "",
  },
  feet: {
    p: "",
    f: "",
    adj: "",
    comments: "",
  },
  canHePlay: {
    p: "",
    f: "",
    adj: "",
    comments: "",
  },
  oFP: {
    p: 0,
    f: 0,
    adj: 0,
  },
  aggressiveness: "",
  athleticism: "",
  bodyControl: "",
  instincts: "",
  competitiveness: "",
  baseballIQ: "",
  handEyeControl: "",
  bp: "",
  infield: "",
  oFPOffense: "",
  oFPDefense: "",
  homeToFirst: "",
  sixtyYard: "",
  fortyYard: "",
  posInOrder: "",
  character: "",
  entryLevel: "",
  eTA: "",
  armAngle: "",
  armAction: {
    value: "",
    comments: "",
  },
  delivery: {
    value: "",
    comments: "",
  },
  howWellKnown: {
    value: "",
    comments: "",
  },
  howWellSeen: {
    value: "",
    comments: "",
  },
  signability: {
    value: "",
    comments: "",
  },

  aptitude: "",
  poise: "",
  fielding: "",
  deception: "",
  releaseTimes: "",
  outpitch: "",

  fB: {
    p: "",
    f: "",
    adj: "",
    h: "",
    l: "",
    a: "",
  },
  fBMVMT: {
    p: "",
    f: "",
    adj: "",
    h: "",
    l: "",
    a: "",
  },
  fBStretch: {
    p: "",
    f: "",
    adj: "",
    h: "",
    l: "",
    a: "",
  },
  sL: {
    p: "",
    f: "",
    adj: "",
    h: "",
    l: "",
    a: "",
  },
  cB: {
    p: "",
    f: "",
    adj: "",
    h: "",
    l: "",
    a: "",
  },
  cH: {
    p: "",
    f: "",
    adj: "",
    h: "",
    l: "",
    a: "",
  },
  control: {
    p: "",
    f: "",
    adj: "",
    h: "",
    l: "",
    a: "",
  },
  command: {
    p: "",
    f: "",
    adj: "",
    h: "",
    l: "",
    a: "",
  },
  canHePitch: {
    p: "",
    f: "",
    adj: "",
    h: "",
    l: "",
    a: "",
  },

  videos: [],
  senderName: undefined,
  report: undefined
};

export const EmptyGeneralReport = {
  kind: "GENERAL",
  playerName: null,
  playerId: null,
  date: null,
  relatedGames: [],
  summary: "",
  videos: [],
  photos: [],
};

export const EmptyNotesReport = {
  kind: "NOTES",
  playerName: null,
  playerId: null,
  date: null,
  relatedGames: [],
  summary: "",
};
