import React from "react";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FriendName from "popovers/friend-name";
import { Button } from "react-bootstrap";
import { AiFillPlusCircle } from "react-icons/ai";
import CreateRequestModal from "../modals/create-request-modal";
// import { ScoutNotification } from "models/ScoutNotification";
import { PositionReport } from "models/PositionReport";
import ReportName from "popovers/report-name";
import colors from "constants/colors";
import ScoutTitle from "./scout-title";
import CTALink from "./cta-link";

function SidebarReports(props: any) {
  const userId = useSelector((state: any) => state.firebase.auth.uid);
  const myReports = useSelector((state: any) => state.firestore.data.myReports);
  useFirestoreConnect([
    {
      collection: "users",
      doc: userId,
      subcollections: [{ collection: "reports" }],
      storeAs: `myReports`,
    },
  ]);

  const sortedReports = (): PositionReport[] => {
    if (!isLoaded(myReports)) {
      return [];
    }

    let reports = myReports
      ? Object.keys(myReports).map((key) =>
          myReports ? { ...myReports[key], id: key } : null
        )
      : [];

    return reports.filter((item) => item !== null && item.kind !== "NOTES");
  };

  return props.hideFriends ? (
    <div />
  ) : (
    <div
      style={{
        backgroundColor: colors.white,
        marginBottom: 20,
        marginTop: -10,
      }}
    >
      <div
        style={{
          display: "flex",
          marginTop: 30,
          flexDirection: "row",
          padding: "1rem",
          paddingBottom: 0,
        }}
      >
        <ScoutTitle text="Recent Reports" styles={{ padding: 0 }} />
        <Link
          to="/create-report/any"
          style={{
            marginTop: -8,
            fontSize: 20,
            backgroundColor: "transparent",
            padding: 6,
            marginLeft: 3,
            borderWidth: 0,
            boxShadow: "none",
          }}
        >
          <AiFillPlusCircle />
        </Link>
      </div>

      {(sortedReports() || []).length > 0 ? (
        <div
          style={{
            flex: 1,

            padding: "1rem",
            paddingBottom: 0,
            borderRadius: 6,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {(sortedReports() || []).map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <ReportName
                  report={item}
                  left={true}
                  hover={true}
                  containerStyles={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 8,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              </div>
            );
          })}
          <CTALink
            styles={{ marginBottom: 12, marginTop: 12 }}
            linkTo={`/reports`}
            text={"VIEW ALL"}
          />
        </div>
      ) : (
        <div
          style={{
            flex: 1,
            minHeight: 80,
            backgroundColor: colors.white,
            padding: "1rem",
            borderRadius: 6,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p style={{ fontStyle: "italic", opacity: 0.8 }}>
            No reports created yet.
          </p>
        </div>
      )}
    </div>
  );
}

export default SidebarReports;
