import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import ScoutTitle from "shared/scout-title";
import SaveSearchModal from "modals/save-search-modal";
import HelpModal from "modals/help-modal";
import ResultTableNCAA from "./result-item-ncaa";
import {
  fetchFilterData,
  fetchPaginatedData,
  getAhowAllQuery,
  getTotalItemCount,
} from "./ncaaPortal";
import colors from "constants/colors";
import { MdChevronRight } from "react-icons/md";
import { Link } from "@material-ui/core";
import moment from "moment";
import UnderlineButton from "shared/underline-button";

function PortalResults(props) {
  const [results, setResults] = useState(
    (props.showWithoutFilter && props.data) || []
  );
  const [totalCount, setTotalCount] = useState(0);
  const [lastDoc, setLastDoc] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [localFilters, setLocalFilters] = useState(props.filters);
  const [showHelp, setShowHelp] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // Sorting State
  const [sortColumn, setSortColumn] = useState("");
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | "">("");

  const fetchData = async (newPageSize, lastDoc = null) => {
    setLoading(true);
    if (props.limit && localFilters) {
      const { data, lastDoc: newLastDoc } = await fetchFilterData(
        newPageSize + 20,
        localFilters,
        lastDoc
      );
      setResults(data);
      setLastDoc(newLastDoc);
    } else {
      const { data, lastDoc: newLastDoc } = await fetchPaginatedData(
        newPageSize + 20,
        lastDoc
      );
      setResults(data);
      setLastDoc(newLastDoc);
    }
    setLoading(false);
    if (results?.length >= totalCount) {
      setHasMore(true);
    }
  };

  useEffect(() => {
    const initialize = async () => {
      if (!props.showWithoutFilter) {
        const ncaa_portals_data = getAhowAllQuery(pageSize);
        ncaa_portals_data.then((data) => {
          setResults(data);
        });
      }
    };
    initialize();
  }, []);

  useEffect(() => {
    const initialize = async () => {
      if (!props.limit) {
        const count = await getTotalItemCount();
        setTotalCount(count);
      }
    };
    initialize();
  }, []);

  useEffect(() => {
    if (sortColumn) {
      const sortedResults = [...results].sort((a, b) => {
        const aValue = a[sortColumn]?.toString() ?? "";
        const bValue = b[sortColumn]?.toString() ?? "";
        if (sortOrder === "asc") return aValue.localeCompare(bValue);
        if (sortOrder === "desc") return bValue.localeCompare(aValue);
        return 0;
      });
      setResults(sortedResults);
    }
  }, [sortColumn, sortOrder]);

  // Handle sorting column toggling
  const handleSort = (key: string) => {
    if (sortColumn === key) {
      setSortOrder((prevOrder) =>
        prevOrder === "asc" ? "desc" : prevOrder === "desc" ? "" : "asc"
      );
    } else {
      setSortColumn(key);
      setSortOrder("asc");
    }
  };

  const handleLoadMore = async () => {
    setPageSize(pageSize + 20);
    await fetchData(pageSize, lastDoc);
  };

  return (
    <>
      <div className="search-container" style={{ minHeight: "95vh" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "85vh",
            marginLeft: "1rem",
            padding: "10px",
            flex: 1,
            overflowX: "scroll",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <ScoutTitle
              text="RESULTS"
              withHelp={true}
              onClick={() => setShowHelp(true)}
              styles={{ color: "white" }}
              light={true}
            />
          </div>

          {results?.length > 0 ? (
            <ResultTableNCAA
              data={results}
              sortColumn={sortColumn}
              sortOrder={sortOrder}
              onSort={handleSort}
            />
          ) : (
            <p
              style={{
                fontStyle: "italic",
                opacity: 0.8,
                marginTop: 100,
                textAlign: "center",
                color: "white",
              }}
            >
              {loading ? "" : "No results."}
            </p>
          )}

          {/* Load More Button */}
          {hasMore && !loading && (
            <div
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                marginTop: "20px",
              }}
            >
              <Button
                style={{
                  color: colors.accent,
                  backgroundColor: "transparent",
                  padding: 6,
                  borderWidth: 0,
                  boxShadow: "none",
                  whiteSpace: "nowrap",
                }}
                onClick={handleLoadMore}
              >
                <p
                  style={{
                    color: "white",
                    textAlign: "center",
                    opacity: 0.6,
                    textDecorationColor: colors.accent,
                    textDecoration: "underline",
                    textDecorationStyle: "solid",
                    fontSize: "22px",
                  }}
                >
                  Load More
                </p>
              </Button>
            </div>
          )}
          {localFilters && (
            <div
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                marginTop: "10px",
              }}
            >
              <Button
                style={{
                  color: colors.accent,
                  backgroundColor: "transparent",
                  padding: 6,
                  borderWidth: 0,
                  boxShadow: "none",
                  whiteSpace: "nowrap",
                }}
                onClick={() => setShowModal(true)}
              >
                <p
                  style={{
                    color: "white",
                    textAlign: "center",
                    opacity: 0.6,
                    textDecorationColor: colors.accent,
                    textDecoration: "underline",
                    textDecorationStyle: "solid",
                    fontSize: "22px",
                  }}
                >
                  Save Search
                </p>
              </Button>
            </div>
          )}
          <p
            style={{
              textAlign: "center",
              marginTop: 10,
              color: "white",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            Total Results: {results?.length}
          </p>
        </div>
        {/* Spinner while loading */}
        {loading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 9999,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                color: "black",
              }}
            >
              <Spinner
                animation="border"
                style={{ width: "2rem", height: "2rem" }}
              />
            </div>
          </div>
        )}
        <SaveSearchModal
          onHide={() => setShowModal(false)}
          count={results.length}
          query={localFilters}
          show={showModal}
          ncaaPortal={true}
        />
        <HelpModal
          show={showHelp}
          onHide={() => setShowHelp(false)}
          kind={"SEARCH"}
        />
      </div>
    </>
  );
}

export default PortalResults;
