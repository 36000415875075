import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import { useLocation } from "react-router-dom";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  useFirestoreConnect,
  isLoaded,
  withFirestore,
} from "react-redux-firebase";
import { useSelector } from "react-redux";
import colors from "constants/colors";
import { compose } from "redux";
import CalendarEvent from "popovers/calendar-event";
import CreateEventModal from "modals/create-event-modal";
import { Spinner } from "react-bootstrap";
import HelpModal from "modals/help-modal";
import ScoutTitle from "shared/scout-title";
import AreYouSureModal from "modals/are-you-sure-modal";
import { toast } from "react-toastify";
import moment from "moment";
import qs from "qs";
import { PlayerService } from "services/PlayerService";

function Calendar(props: any) {
  const userId = useSelector((state: any) => state.firebase.auth.uid);
  const [loaded, setLoaded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [editingEvent, setEditingEvent] = useState(null);
  const myProspects = useSelector((state: any) => state.firestore.data.myProspects);
  const [currentPlayerId, setCurrentPlayerId] = useState(null);
  const myEvents = useSelector((state: any) => state.firestore.data.myEvents);
  const myReminders = useSelector((state: any) => state.firestore.data.myReminders);
  const [showHelp, setShowHelp] = useState(false);
  const [gameToDelete, setGameToDelete] = useState(null);
  let location = useLocation();

  useFirestoreConnect([
    {
      collection: "users",
      doc: userId,
      subcollections: [
        { collection: "prospects", where: ["active", "==", true] },
      ],
      storeAs: "myProspects",
    },
    {
      collection: "users",
      doc: userId,
      subcollections: [{ collection: "events" }],
      storeAs: `myEvents`,
    },

    {
      collection: "users",
      doc: userId,
      subcollections: [{ collection: "reminders" }],
      storeAs: `myReminders`,
    },
  ]);

  useEffect(() => {
    setTimeout((_) => {
      setLoaded(true);
    }, 2000);
  }, []);

  useEffect(() => {
    const data = location.search;
    const params = qs.parse(data, { ignoreQueryPrefix: true });
    if (params.player) {
      setCurrentPlayerId(params.player);
    }
  }, []);

  if (!loaded) {
    return (
      <div
        style={{
          display: "flex",
          marginLeft: "1rem",
          backgroundColor: colors.white,
          flex: 1,
          flexDirection: "column",
          minHeight: "80vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spinner animation="border" />
      </div>
    );
  }

  const sortedEvents = () => {
    if (!isLoaded(myEvents)) {
      return [];
    }

    let events = myEvents
      ? Object.keys(myEvents).map((key) =>
          myEvents ? { ...myEvents[key], id: key, reminder: false } : null
        )
      : [];

    events = events.filter((item) => item.eventDate && item.eventDate.seconds);

    return events;
  };

  const sortedReminders = () => {
    if (!isLoaded(myReminders)) {
      return [];
    }

    let events = myReminders
      ? Object.keys(myReminders).map((key) =>
          myReminders ? { ...myReminders[key], id: key, reminder: true } : null
        )
      : [];

    events = events.filter((item) => item.eventDate && item.eventDate.seconds);

    return events;
  };

  const sortedProspects = () => {
    if (!isLoaded(myProspects)) {
      return [];
    }

    let prospects = myProspects
      ? Object.keys(myProspects).map((key) =>
          myProspects ? { ...myProspects[key], id: key } : null
        )
      : [];

    return prospects.filter((item) => item !== null && item.active);
  };

  const renderEventContent = (eventInfo) => {
    return (
      <CalendarEvent
        eventInfo={eventInfo}
        prospects={sortedProspects()}
        onEdit={(event) => {
          setEditingEvent(event);
          setShowModal(true);
        }}
        onDelete={(event) => {
          setGameToDelete(event);
        }}
      />
    );
  };

  const handleDateSelect = (selectInfo) => {
    setSelectedDate(selectInfo.start);
    let calendarApi = selectInfo.view.calendar;
    calendarApi.unselect();
    setShowModal(true);
  };

  const handleEventChanged = async ({
    event,
    relatedEvents,
    oldEvent,
    revert,
  }) => {
    let newStart = moment(new Date(event.start)).hour(12).toDate();
    let id = oldEvent.extendedProps.source.id;

    let docRefConfig = {
      collection: "users",
      doc: userId,
      subcollections: [{ collection: "events", doc: id }],
    };

    await props.firestore.update(docRefConfig, {
      eventDate: newStart,
    });
  };

  const deleteEvent = async (event) => {
    let id = event.id;

    let docRefConfig = {
      collection: "users",
      doc: userId,
      subcollections: [{ collection: "events", doc: id }],
    };

    if (event.reminder) {
      docRefConfig = {
        collection: "users",
        doc: userId,
        subcollections: [{ collection: "reminders", doc: id }],
      };
    }

    await props.firestore.delete(docRefConfig);

    toast.dark(event.reminder ? "Reminder removed." : `Game removed.`, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  return (
    <div style={{ marginLeft: "1rem", backgroundColor: colors.white }}>
      <ScoutTitle
        text="CALENDAR"
        withHelp={true}
        onClick={() => setShowHelp(true)}
        styles={{ marginLeft: "3rem" }}
      />
      <div
        style={{
          padding: "3rem",
          paddingTop: "0rem",
        }}
      >
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek",
          }}
          contentHeight={1000}
          initialView="dayGridMonth"
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          allDaySlot={false}
          slotDuration={"01:00:00"}
          expandRows={true}
          eventColor={"transparent"}
          slotMinTime={"07:00:00"}
          events={[...sortedEvents(), ...sortedReminders()].map((item) => ({
            title: item.reminder
              ? `Reminder: ${PlayerService.getName(item.player)}`
              : `${item.teamOne ? item.teamOne : "Team One"} V. ${
                  item.teamTwo ? item.teamTwo : "Team Two"
                }`,
            date: new Date(item.eventDate.seconds * 1000),
            editable: true,
            source: {
              ...item,
            },
          }))}
          select={handleDateSelect}
          eventContent={renderEventContent}
          eventChange={handleEventChanged}
        />
        {showModal ? (
          <CreateEventModal
            onHide={() => {
              setEditingEvent(null);
              setShowModal(false);
            }}
            currentPlayerId={currentPlayerId}
            show={showModal}
            eventDate={selectedDate}
            editing={editingEvent}
          />
        ) : (
          <div />
        )}
      </div>

      <HelpModal
        show={showHelp}
        kind={"CALENDAR"}
        onHide={() => setShowHelp(false)}
      />

      <AreYouSureModal
        show={gameToDelete !== null}
        onConfirm={async () => {
          deleteEvent(gameToDelete);
          setGameToDelete(null);
        }}
        onHide={() => setGameToDelete(null)}
        title={
          gameToDelete && gameToDelete.reminder
            ? "Delete Reminder"
            : "Delete Game"
        }
        body={
          gameToDelete && gameToDelete.reminder
            ? `Are you sure you want to delete this reminder?`
            : `Are you sure you want to delete this game?`
        }
      />
    </div>
  );
}

const enhance = compose(withFirestore);

export default enhance(Calendar);
