import colors from "constants/colors";
import React, { useState } from "react";
import { compose } from "recompose";
import {
  isLoaded,
  withFirestore,
  useFirestoreConnect,
  useFirestore,
} from "react-redux-firebase";
import { useSelector } from "react-redux";
import { Button, Modal, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
// import { User } from "models/User";
import { NotificationService } from "services/NotificationService";
import { Player } from "models/Player";
import { PlayerService } from "services/PlayerService";
import { FBAnalytics } from "store/store";

function SharePlayerModal(props: {
  show: boolean;
  firestore: any;
  onHide: any;
  friends: any[];
  player: Player;
  me: any;
}) {
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState<String[]>([]);
  const firestore = useFirestore();
  const [previousSent, setPreviousSent] = useState<String[]>([]);

  React.useEffect(() => {
    if (props.show) {
      fetchPreviousSend();
    }
  }, [props.show]);

  const fetchPreviousSend = async () => {
    let snapshot = await firestore
      .collection("notifications")
      .where("sender", "==", props.me.id)
      .where("kind", "==", "sharedProspect")
      .get();
    let results = [];
    snapshot.forEach((doc) => results.push({ ...doc.data(), id: doc.id }));
    results = results.filter(
      (item) =>
        item.prospect && item.prospect.external_id == props.player.external_id
    );
    setPreviousSent(results.map((item) => item.recipient));
  };

  const send = async (user: any) => {
    setLoading(true);

    await NotificationService.sendProspect(
      props.player,
      user,
      props.me,
      props.firestore
    );

    setLoading(false);
    setSent([...sent, user.id.toString()]);

    FBAnalytics.logEvent("share", {
      from: props.me.id,
      to: user.id,
      share_type: "prospect",
    });

    toast.dark(`Prospect sent to ${user.firstName} ${user.lastName}`, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });
  };

  return (
    <Modal
      {...props}
      //@ts-ignore
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{
          color: colors.white,
          backgroundColor: colors.accent,
          borderBottomWidth: 0,
        }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          {`Share ${PlayerService.getName(props.player)} with a friend?`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ color: colors.white, backgroundColor: colors.accent }}
      >
        {loading ? (
          <Spinner animation="border" />
        ) : (
          <div>
            {props.friends.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 8,
                  }}
                >
                  <div>
                    <p>{`${item.firstName} ${item.lastName}`}</p>
                    {previousSent.includes(item.id as unknown as string) ? (
                      <p
                        style={{ fontStyle: "italic", fontSize: 12 }}
                      >{`(shared previously)`}</p>
                    ) : (
                      <div />
                    )}
                  </div>
                  {sent.includes(item.id as unknown as string) ? (
                    <p
                      style={{
                        fontStyle: "italic",
                        opacity: 0.7,
                        width: 60,
                        textAlign: "center",
                      }}
                    >
                      sent
                    </p>
                  ) : (
                    <Button variant="secondary" onClick={() => send(item)}>
                      Send
                    </Button>
                  )}
                </div>
              );
            })}
            {props.friends.filter((item) => !sent.includes(item.id as unknown as string)).length ==
            0 ? (
              <p>No friends available.</p>
            ) : (
              <div />
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            props.onHide();
            setSent([]);
          }}
        >
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const enhance = compose(withFirestore);

export default enhance(SharePlayerModal);
